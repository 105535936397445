import { gql } from '@apollo/client';

import fragmentConditionElementNode from './fragmentConditionElementNode';

export default gql`
  fragment LogicFragment on RegformForm {
    logics {
      nodes {
        id
        condition {
          id
          typeName: __typename
          ... on RegformCompoundCondition {
            subConditions {
              nodes {
                ...ConditionElementNode
              }
            }
          }
        }
        targetElement {
          id
          typeName: __typename
        }
      }
    }
  }
  ${fragmentConditionElementNode}
`;
