import { fetchRsvpNoThankYouPageQuery } from './Graphql';

const mapLang = (locales) => {
  const langMapping = {
    en: 'English',
    zh_hk: '繁體中文',
    zh_cn: '简体中文',
    ja: '日本語',
    ko: '한국어',
    ru: 'русский',
  };
  const emsMapping = {
    en: 'en',
    zh_hk: 'zh-HK',
    zh_cn: 'zh-CN',
    ja: 'ja',
    ko: 'ko',
    ru: 'ru',
  };
  return locales.map((eachLang) => ({
    code: eachLang,
    display: langMapping[eachLang],
    emsMapping: emsMapping[eachLang],
  }));
};

const rsvpNoThankYouPage = {
  state: [],
  reducers: {
    setRsvpNoThankYouPage(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    fetchRsvpNoThankYouPage({ client, queue }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .query({
              variables: { orderAccessKey: state.orderAccessKey },
              query: fetchRsvpNoThankYouPageQuery,
              fetchPolicy: 'no-cache',
            })
            .then((result) => {
              const {
                data: {
                  orderByAccessKey: {
                    status,
                    regform: {
                      backgroundColor,
                      themeColor,
                      locales,
                      fontFamily,
                    },
                    rsvpNoThankYouPageViewElement: {
                      pages: { nodes: rsvpNothankYouPageNodes },
                    },
                  },
                },
              } = result;
              dispatch.languageList.updateLanguageList(locales);
              const searchParams = new URLSearchParams(window.location.search);
              dispatch.locale.initFromSearchParams(searchParams);
              dispatch.themeColor.setThemeColor(themeColor);
              dispatch.backgroundColor.setBackgroundColor(backgroundColor);
              dispatch.footerColorMode.setFooterColorModeByColor(backgroundColor);
              if (fontFamily) dispatch.fontFamily.setFontFamily(fontFamily);
              const languageList = [];
              mapLang(locales).forEach(({ code }) => { languageList.push(code); });
              const thankYouPageComponentList = [];
              const { elements } = rsvpNothankYouPageNodes.shift();
              elements[languageList[0]].forEach((eachRow, index) => {
                let eachLandingComponent = {};
                languageList.forEach((eachLanguage) => {
                  eachLandingComponent = {
                    ...eachLandingComponent,
                    [eachLanguage]: elements[eachLanguage][index],
                  };
                });
                eachLandingComponent = {
                  ...eachLandingComponent,
                  id: eachLandingComponent[languageList[0]].id,
                  typeName: eachLandingComponent[languageList[0]].typeName,
                };
                thankYouPageComponentList.push(eachLandingComponent);
              });
              dispatch.rsvpNoThankYouPage.setRsvpNoThankYouPage(thankYouPageComponentList);
              if (['PENDING', 'QUOTA_LOCKED'].includes(status)) {
                dispatch.currentInQueue.rsvpOrderFinalize();
              }
              resolve();
            })
            .catch((error) => {
              // window.Sentry.captureException(error);
              console.error(error);
              reject(error);
            });
        }),
      );
    },
  }),
};

export default rsvpNoThankYouPage;
