import { updateRegFormMutation } from '../Graphql';

const formSettings = {
  state: {},
  reducers: {
    setFormSettings(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: (dispatch) => ({
    updateFormSettingMutation({ queue, client }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          const attributes = Object.keys(state.formSettings).map((key) => ({
            name: key,
            value: { stringValue: state.formSettings[key] },
          }));
          client
            .mutate({
              variables: {
                regFormElementId: state.regFormElementId,
                attributes,
              },
              mutation: updateRegFormMutation,
            })
            .then((data) => {
              const {
                data: {
                  regformBuilderObjectSetAttributes: { success },
                },
              } = data;
              dispatch.networkError.setNetworkError(!success);
              resolve(true);
            })
            .catch((error) => {
              dispatch.networkError.setNetworkError(true);
              // window.Sentry.captureException(error);
              reject(error);
            })
            .finally(() => {
              dispatch.currentInQueue.updateCurrentInQueue(
                queue.size + queue.pending,
              );
            });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
    },
  }),
};

export default formSettings;
