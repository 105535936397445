import moment from 'moment';
import { fetchEventTicketQuery, fetchFormTicketQuery } from '../Graphql';

const eventTicket = {
  state: {},
  reducers: {
    setEventTicket(state, payload) {
      return payload;
    },
    addEventTicket(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    setTicketDisable(state, ticketId, value) {
      const newState = { ...state };
      newState[ticketId].isDisabled = value;
      return newState;
    },
    resetIsDisabled(state, id, value) {
      const newState = { ...state };
      newState[id].isDisabled = value;
      return newState;
    },
    resetDisabledState(state, id) {
      const newState = { ...state };
      newState[id].isDisabled = newState[id].isDefaultTicket
        || moment(newState[id].salesEndAt).isBefore(moment());
      return newState;
    },
  },
  effects: (dispatch) => ({
    fetchTicketQuery({ queue, client }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          dispatch.isTicketLoading.setIsTicketLoading(true);
          dispatch.selectedTicket.setSelectedTicket([]);
          dispatch.eventTicket.fetchEventTicket({ queue, client, after: '' });
          resolve();
        }, {
          priority: 1,
        }),
      );
    },
    fetchEventTicket({ queue, client, after }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .query({
              variables: {
                eventId: state.eventId,
                locale: state.locale.code,
                after,
                first: 50,
              },
              fetchPolicy: 'no-cache',
              query: fetchEventTicketQuery,
            })
            .then(({ data: { eventNode } }) => {
              const {
                tickets: {
                  pageInfo: {
                    endCursor,
                    hasNextPage,
                  },
                  edges,
                },
              } = eventNode;
              let ticketList = {};
              const eventTicketList = edges.map(({ node }) => (node));
              eventTicketList.forEach((eachTicket) => {
                const { salesEndAt, isDefaultTicket } = eachTicket;
                if (!isDefaultTicket) {
                  const isTicketSalesEnded = moment(salesEndAt).isBefore(moment());
                  ticketList = {
                    ...ticketList,
                    [eachTicket.id]: {
                      ...eachTicket,
                      isDisabled: isTicketSalesEnded,
                    },
                  };
                }
              });
              dispatch.eventTicket.addEventTicket(ticketList);
              if (hasNextPage) {
                dispatch.eventTicket.fetchEventTicket({ queue, client, after: endCursor });
              } else {
                dispatch.eventTicket.fetchFormTicket({ queue, client, after: '' });
              }
              resolve();
            })
            .catch((error) => {
            // window.Sentry.captureException(error);
              dispatch.hasError.setHasError({ error: true, message: 'fetch_ticketing_fail' });
              dispatch.showHelpButton.toggleShowHelpButton();
              reject(error);
            });
        }, {
          priority: 1,
        }),
      );
    },
    fetchFormTicket({ queue, client, after }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .query({
              variables: {
                regformId: state.regFormElementId,
                locale: state.locale.code,
                after,
                first: 50,
              },
              fetchPolicy: 'no-cache',
              query: fetchFormTicketQuery,
            })
            .then(({ data: { formNode } }) => {
              const {
                ticketingElement: {
                  id,
                  ticketItems: {
                    pageInfo: {
                      endCursor,
                      hasNextPage,
                    },
                    edges,
                  },
                },
              } = formNode;
              dispatch.ticketElementID.setTicketElementID(id);
              const selectedList = edges.map(({ node }) => (node));
              selectedList.filter((eachSelectedTicket) => {
                const { ticket: { id: ticketId, isDefaultTicket } } = eachSelectedTicket;
                if (!isDefaultTicket) {
                  dispatch.eventTicket.setTicketDisable(ticketId, true);
                }
                return !isDefaultTicket;
              });
              dispatch.selectedTicket.addSelectTicket(selectedList);
              if (hasNextPage) {
                dispatch.eventTicket.fetchFormTicket({ queue, client, after: endCursor });
              } else {
                dispatch.isTicketLoading.setIsTicketLoading(false);
              }
              resolve();
            })
            .catch((error) => {
              // window.Sentry.captureException(error);
              dispatch.hasError.setHasError({ error: true, message: 'fetch_ticketing_fail' });
              dispatch.showHelpButton.toggleShowHelpButton();
              reject(error);
            });
        }, {
          priority: 1,
        }),
      );
    },
  }),
};

export default eventTicket;
