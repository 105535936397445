const eventId = {
  state: '',
  reducers: {
    setEventId(state, payload) {
      return payload;
    },
  },
};

export default eventId;
