import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import RegformDisplayElement from 'schema/displayElement';
import FormValueType from 'schema/formValueType';
import { v4 as uuidv4 } from 'uuid';
import { fetchThankYouPageQuery } from '../Graphql';

const thankYouPage = {
  state: [],
  reducers: {
    setThankYouPage(state, payload) {
      return payload;
    },
    addComponentToForm(state, newFormComponent, index) {
      const newState = _cloneDeep(state);
      if (index < 0) {
        newState.splice(
          newState.length,
          0,
          newFormComponent,
        );
      } else {
        newState.splice(index, 0, newFormComponent);
      }
      return newState;
    },
    rearrangeComponent(state, sourceIndex, destinationIndex) {
      const targetComponent = { ...state[sourceIndex] };
      const newState = [...state];
      newState.splice(sourceIndex, 1);
      newState.splice(destinationIndex, 0, targetComponent);
      return newState;
    },
    updateComponentAttr(state, { targetObjectId, attrName, value }) {
      const newState = state.map((eachComponent) => {
        if (eachComponent.id === targetObjectId) {
          let newComponent = _cloneDeep(eachComponent);
          newComponent = {
            ...newComponent,
            [attrName]: value,
          };
          return newComponent;
        }
        return eachComponent;
      });
      return newState;
    },
    removeComponent(state, componentId) {
      const newState = state.filter(
        (eachComponent) => eachComponent.id !== componentId,
      );
      return newState;
    },
    replaceComponent(state, payload) {
      const newState = state.map((eachComponent) => {
        if (eachComponent.id === payload.id) {
          return payload;
        }
        return eachComponent;
      });
      return newState;
    },
  },
  effects: (dispatch) => ({
    fetchThankYouPageQuery({ payload, queue, client }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          dispatch.isThankYouPageLoading.setIsThankYouPageLoading(true);
          client
            .query({
              variables: {
                eventId: state.eventId,
                regformId: state.regFormElementId,
              },
              query: fetchThankYouPageQuery,
              fetchPolicy: 'no-cache',
            })
            .then(({ data: { node } }) => {
              const {
                status,
                thankYouPageViewElement: {
                  pages: { nodes },
                },
                rsvpNoThankYouPageViewElement,
              } = node;
              if (rsvpNoThankYouPageViewElement) {
                dispatch.rsvpNoThankYouPage.parsePageInfo(rsvpNoThankYouPageViewElement);
              }
              dispatch.regFormStatus.setRegFormStatus(status);
              dispatch.thankYouPageId.setThankYouPageId(nodes[0].id);
              const componentList = [];
              nodes[0].elements[`${state.locale.code}`].forEach((each, elementIndex) => {
                let newComponent = _cloneDeep(
                  state.component[each.typeName],
                );
                const {
                  builderAttributes: componentBuilderAttributes,
                  typeName,
                } = each;
                const newAttrs = {};
                componentBuilderAttributes.forEach((eachComponent, attrIndex) => {
                  switch (eachComponent.valueType) {
                    case FormValueType.STRING:
                      if (eachComponent.value.stringValue) {
                        newAttrs[eachComponent.name] = eachComponent.value.stringValue;
                      } else {
                        state.languageList.some((eachLanguage) => {
                          if (nodes[0].elements[`${eachLanguage.code}`][elementIndex].builderAttributes[attrIndex].value.stringValue) {
                            newAttrs[eachComponent.name] = nodes[0].elements[`${eachLanguage.code}`][elementIndex].builderAttributes[attrIndex].value.stringValue;
                            return true;
                          }
                          return false;
                        });
                      }
                      break;
                    case FormValueType.FILE:
                      if (typeName === RegformDisplayElement.Image) {
                        if (eachComponent.value.fileValue) {
                          newAttrs.url = eachComponent.value.fileValue.url;
                        } else {
                          state.languageList.some((eachLanguage) => {
                            if (nodes[0].elements[`${eachLanguage.code}`][elementIndex].builderAttributes[attrIndex].value.fileValue) {
                              newAttrs.url = nodes[0].elements[`${eachLanguage.code}`][elementIndex].builderAttributes[attrIndex].value.fileValue.url;
                              return true;
                            }
                            return false;
                          });
                        }
                      }
                      break;
                    default:
                      break;
                  }
                });
                newComponent = {
                  ...newComponent,
                  id: each.id,
                  typeName: each.typeName,
                  idUpdated: true,
                  keyId: uuidv4(),
                };
                _merge(newComponent, newAttrs);
                componentList.push(newComponent);
              });
              dispatch.thankYouPage.setThankYouPage(componentList);
              dispatch.isThankYouPageLoading.setIsThankYouPageLoading(false);
              resolve();
            })
            .catch((error) => {
              // window.Sentry.captureException(error);
              dispatch.hasError.setHasError({ error: true, message: 'fetch_thank_you_fail' });
              dispatch.showHelpButton.toggleShowHelpButton();
              reject(error);
            })
            .finally(() => {
              if (payload) {
                dispatch.currentInQueue.updateCurrentInQueue(
                  queue.size + queue.pending,
                );
              }
            });
        }),
      );
    },
    removeFormComponent({ componentId, targetObjectId }, state) {
      const targetComponent = state.thankYouPage.find(
        (each) => each.id === componentId,
      );
      dispatch.component.resetIsDisable(targetComponent.typeName);
      dispatch.thankYouPage.removeComponent(componentId);
      dispatch.currentInQueue.deleteComponent({ componentId, targetObjectId });
    },
  }),
};

export default thankYouPage;
