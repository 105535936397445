const currentTab = {
  state: 0,
  reducers: {
    setCurrentTab(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    switchCurrentTab(result, state) {
      switch (result) {
        case 0:
          dispatch.currentTab.setCurrentTab(result);
          dispatch.preview.setPreviewMode(false);
          dispatch.active.setActiveMode(false);
          dispatch.currentEditLogicalOption.resetOptionAttr();
          break;
        case 1:
          dispatch.currentTab.setCurrentTab(result);
          dispatch.preview.setPreviewMode(false);
          dispatch.active.setActiveMode(false);
          dispatch.formStage.setFormStage(2);
          dispatch.logicsAffectedByReorder.setAffectedQuestions([]);
          dispatch.currentEditComponent.updateCurrentEditComponent({ formStage: state.formStage });
          dispatch.logicalConditions.updateConditionLabelText();
          break;
        case 2:
          dispatch.currentTab.setCurrentTab(result);
          dispatch.active.setActiveMode(true);
          dispatch.preview.setPreviewMode(false);

          dispatch.currentEditComponent.updateCurrentEditComponent({ formStage: state.formStage });
          dispatch.currentEditLogicalOption.resetOptionAttr();
          break;
        default:
          dispatch.currentTab.setCurrentTab(result);
          dispatch.preview.setPreviewMode(true);

          dispatch.currentEditComponent.updateCurrentEditComponent({ formStage: state.formStage });
          dispatch.currentEditLogicalOption.resetOptionAttr();
          break;
      }
    },
  }),
};
export default currentTab;
