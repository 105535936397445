const qrCodeUrl = {
  state: {},
  reducers: {
    setQrCodeUrl(state, payload) {
      return payload;
    },
  },
};

export default qrCodeUrl;
