const formData = {
  state: [],
  reducers: {
    updateFormData(state, payload) {
      return payload;
    },
  },
};

export default formData;
