import { gql } from '@apollo/client';

const updateComponentAttrMutation = gql`
  mutation updateComponentAttrMutation(
    $targetObjectId: ID!
    $attributes: [RegformBuilderAttributeInput!]!
    $regFormElementId: ID!
  ) {
    regformBuilderObjectSetAttributes(
      input: {
        regformId: $regFormElementId
        targetObjectId: $targetObjectId
        attributes: $attributes
      }
    ) {
      targetObject {
        id
      }
      success
      errors {
        details {
          value
        }
        message
        path
        type
      }
    }
  }
`;

export default updateComponentAttrMutation;
