const discountCodeError = {
  state: '',
  reducers: {
    setDiscountCodeError(state, payload) {
      return payload;
    },
  },
};

export default discountCodeError;
