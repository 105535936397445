import _cloneDeep from 'lodash/cloneDeep';
import { reorderOptionMutation } from '../Graphql';

const currentEditComponent = {
  state: {},
  reducers: {
    setCurrentEditComponent(state, payload) {
      return payload;
    },
    updateChoiceId(state, oldId, newId) {
      const {
        availableChoices: { choices },
      } = state;
      const newChoices = choices.map((eachChoice) => {
        if (eachChoice.value === oldId) {
          eachChoice = {
            ...eachChoice,
            value: newId,
            isCreated: true,
          };
        }
        return eachChoice;
      });
      let newState = state;
      newState = {
        ...newState,
        availableChoices: { choices: newChoices },
      };
      return newState;
    },
    reorderQuestionOption(state, { destination, source }) {
      const reorderedOption = _cloneDeep(state.availableChoices.choices);
      const reorderOption = _cloneDeep(state.availableChoices.choices[source]);
      reorderedOption.splice(source, 1);
      reorderedOption.splice(destination, 0, reorderOption);
      const newState = {
        ...state,
        availableChoices: { choices: reorderedOption },
      };
      return newState;
    },
  },
  effects: (dispatch) => ({
    updateChoiceForCurrentEditComponent({ choiceId, attrName, value }, state) {
      let newState = _cloneDeep(state.currentEditComponent);
      const {
        availableChoices: { choices },
      } = newState;
      const newChoices = choices.map((eachChoice) => {
        if (eachChoice.value === choiceId) {
          eachChoice = {
            ...eachChoice,
            [attrName]: value,
          };
          if (attrName === 'label') {
            eachChoice = {
              ...eachChoice,
              [`${attrName}_${state.locale.code}`]: value,
            };
          }
          let placeholder = '';
          if (!value) {
            let fallBackLanguageCodeList = state.languageList.map(({ code }) => code);
            if (state.locale.code === 'zh_hk') {
              fallBackLanguageCodeList = fallBackLanguageCodeList.filter((code) => code !== 'zh_cn');
              fallBackLanguageCodeList.unshift('zh_cn');
            } else if (state.locale.code === 'zh_cn') {
              fallBackLanguageCodeList = fallBackLanguageCodeList.filter((code) => code !== 'zh_hk');
              fallBackLanguageCodeList.unshift('zh_hk');
            }
            fallBackLanguageCodeList.some((langCode) => {
              const textValue = eachChoice[`${attrName}_${langCode}`];
              if (textValue) {
                placeholder = textValue;
                return true;
              }
              return false;
            });
            eachChoice = {
              ...eachChoice,
              [`${attrName}Placeholder`]: placeholder,
            };
          }
        }
        return eachChoice;
      });
      newState = {
        ...newState,
        availableChoices: { choices: newChoices },
      };
      dispatch.currentEditComponent.setCurrentEditComponent(newState);
    },
    updateComponent({ attrName, value }, state) {
      let newState = _cloneDeep(state.currentEditComponent);
      newState = {
        ...newState,
        [attrName]: value,
      };
      if (['labelText', 'hintText', 'placeholderText'].includes(attrName)) {
        newState = {
          ...newState,
          [`${attrName}_${state.locale.code}`]: value,
        };
        let placeholder = '';
        if (!value) {
          let fallBackLanguageCodeList = state.languageList.map(({ code }) => code);
          if (state.locale.code === 'zh_hk') {
            fallBackLanguageCodeList = fallBackLanguageCodeList.filter((code) => code !== 'zh_cn');
            fallBackLanguageCodeList.unshift('zh_cn');
          } else if (state.locale.code === 'zh_cn') {
            fallBackLanguageCodeList = fallBackLanguageCodeList.filter((code) => code !== 'zh_hk');
            fallBackLanguageCodeList.unshift('zh_hk');
          }
          fallBackLanguageCodeList.some((langCode) => {
            const textValue = newState[`${attrName}_${langCode}`];
            if (textValue) {
              placeholder = textValue;
              return true;
            }
            return false;
          });
          newState = {
            ...newState,
            [`${attrName}Placeholder`]: placeholder,
          };
        }
      }
      dispatch.currentEditComponent.setCurrentEditComponent(newState);
    },
    updateCurrentEditComponent({ formStage, targetComponent }, state) {
      const component = _cloneDeep(state.currentEditComponent);
      switch (formStage) {
        case 0:
          dispatch.landingPage.replaceComponent(component);
          break;
        case 2:
          dispatch.mainForm.replaceComponent(component);
          break;
        case 3:
          if (state.thankYouPageKey === 'yes') {
            dispatch.thankYouPage.replaceComponent(component);
          } else {
            dispatch.rsvpNoThankYouPage.replaceComponent(component);
          }
          break;
        default:
          break;
      }
      dispatch.mainForm.forceUpdateAttrMutation();
      if (!targetComponent) {
        dispatch.currentEditComponent.setCurrentEditComponent({});
      } else if (state.currentEditComponent.id !== targetComponent.id) {
        dispatch.currentEditComponent.setCurrentEditComponent(
          _cloneDeep(targetComponent),
        );
      }
      dispatch.currentEditLogicalOption.resetOptionAttr();
    },
    reorderQuestionOptionMutation({
      optionId, position, componentId, queue, client,
    }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                regFormElementId: state.regFormElementId,
                targetObjectId: componentId,
                optionId,
                position,
              },
              mutation: reorderOptionMutation,
            })
            .then((data) => {
              const {
                data: {
                  regformBuilderCollectionReorderObject: { success },
                },
              } = data;
              dispatch.networkError.setNetworkError(!success);
              resolve(data);
            })
            .catch((error) => {
              dispatch.networkError.setNetworkError(true);
              // window.Sentry.captureException(error);
              reject(error);
            })
            .finally(() => {
              dispatch.currentInQueue.updateCurrentInQueue(
                queue.size + queue.pending,
              );
            });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
      dispatch.networkError.setNetworkError(false);
    },
    updateCurrentEditComponentId({ oldComponentId, newComponentId }, state) {
      if (state.currentEditComponent.id === oldComponentId) {
        dispatch.currentEditComponent.updateComponent({ attrName: 'id', value: newComponentId });
        dispatch.currentEditComponent.updateComponent({ attrName: 'idUpdated', value: true });
        if (state.currentEditComponent.tempValue) {
          dispatch.currentInQueue.updateComponentAttrMutation({
            ...state.currentEditComponent.tempValue,
            targetObjectId: newComponentId,
          });
        }
      }
    },
  }),
};
export default currentEditComponent;
