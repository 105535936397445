const orderExpireTime = {
  state: 3600,
  reducers: {
    setOrderExpireTime(state, payload) {
      return payload;
    },
  },
};

export default orderExpireTime;
