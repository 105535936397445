const isTicketLoading = {
  state: true,
  reducers: {
    setIsTicketLoading(state, payload) {
      return payload;
    },
  },
};

export default isTicketLoading;
