import axios from 'axios';
import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import _merge from 'lodash/merge';
import { List } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

import RegformFieldElement from 'schema/fieldElement';
import FormValueType from 'schema/formValueType';
import RegformDisplayElement from 'schema/displayElement';
import isMcQuestion from 'helpers/isMcQuestion';
import {
  fetchFormByPaginationQuery,
  fetchFormLogicQuery,
  insertComponentMutation,
  deleteComponentMutation,
  reorderComponentMutation,
  updateComponentAttrMutation,
  addOptionMutation,
  removeOptionMutation,
} from '../Graphql';

const PAGINATION_SIZE = parseInt(process.env.NEXT_PUBLIC_PAGINATION_SIZE, 10);

let attributes = [];

const insertComponentToForm = (
  components,
  componentTemplates,
  locale,
  languageList,
  addComponentToForm,
  disableComponent,
) => {
  components.forEach((eachComponent) => {
    const {
      builderAttributes: componentBuilderAttributes,
      typeName: componentTypeName,
    } = eachComponent[locale];
    const newComponent = _cloneDeep(
      componentTemplates[componentTypeName],
    );
    let newAttrs = {
      id: eachComponent[locale].id,
      typeName: componentTypeName,
      idUpdated: true,
      keyId: uuidv4(),
    };
    componentBuilderAttributes.forEach((componentAttr, attrIndex) => {
      const {
        name: componentAttrName,
        value: componentAttrValue,
        valueType: componentAttrValueType,
      } = componentAttr;
      switch (componentAttrValueType) {
        case FormValueType.STRING:
          if (componentAttrValue.stringValue) {
            newAttrs[componentAttrName] = componentAttrValue.stringValue;
          } else if (eachComponent[locale][componentAttrName]) {
            if (componentTypeName === RegformDisplayElement.Text) {
              newAttrs[componentAttrName] = eachComponent[locale][componentAttrName];
            } else {
              newAttrs[`${componentAttrName}Placeholder`] = eachComponent[locale][componentAttrName];
              newAttrs[componentAttrName] = '';
            }
          } else {
            newAttrs[componentAttrName] = '';
          }
          languageList.forEach((eachLanguage) => {
            const componentValueWithEachLanguage = eachComponent[`${eachLanguage.code}`].builderAttributes[attrIndex].value;
            newAttrs[`${componentAttrName}_${eachLanguage.code}`] = componentValueWithEachLanguage.stringValue;
          });
          break;
        case FormValueType.BOOLEAN:
          newAttrs[componentAttrName] = componentAttrValue.booleanValue;
          break;
        case FormValueType.FILE:
          if (componentTypeName === RegformDisplayElement.Image) {
            if (componentAttrValue.fileValue) {
              newAttrs.url = componentAttrValue.fileValue.url;
            } else {
              newAttrs.url = eachComponent[locale].url;
            }
          }
          break;
        case FormValueType.COLLECTION: {
          if (componentAttrName === 'choices') {
            const { collectionValue: { nodes: choices } } = componentAttrValue;
            const choicesList = choices.map(
              ({ builderAttributes: choiceBuilderAttributes, id }, index) => {
                let eachChoice = { value: id, isCreated: true };
                const choiceTextAttr = choiceBuilderAttributes.find((attr) => attr.name === 'choiceText');
                const { value } = choiceTextAttr;
                if (value.stringValue) {
                  eachChoice = {
                    ...eachChoice,
                    label: value.stringValue,
                  };
                } else {
                  const { choices: { nodes: choicesWithLangFallback } } = eachComponent[locale];
                  const placeholder = choicesWithLangFallback
                    .find((choice) => choice.id === id);
                  if (placeholder.choiceText) {
                    eachChoice = {
                      ...eachChoice,
                      labelPlaceholder: placeholder.choiceText,
                      label: '',
                    };
                  }
                }
                languageList.forEach((eachLanguage) => {
                  const choiceObjectWithEachLanguage = eachComponent[`${eachLanguage.code}`].builderAttributes[attrIndex].value.collectionValue.nodes[index];
                  const choiceTextObject = choiceObjectWithEachLanguage.builderAttributes.find((attr) => attr.name === 'choiceText');
                  const { value: choiceTextValue } = choiceTextObject;
                  eachChoice = {
                    ...eachChoice,
                    [`label_${eachLanguage.code}`]: choiceTextValue.stringValue,
                  };
                });
                return eachChoice;
              },
            );
            newAttrs = {
              ...newAttrs,
              availableChoices: {
                choices: choicesList,
              },
            };
          }
          break;
        }
        default:
          break;
      }
    });
    _merge(newComponent, newAttrs);
    addComponentToForm(newComponent, -1);
    disableComponent(componentTypeName);
  });
};

const debounceUpdateAttrMutation = _debounce(
  (
    targetObjectId,
    attributesArray,
    callBack,
    regFormElementId,
    updateCurrentInQueue,
    queue,
    client,
    setNetworkError,
  ) => {
    queue.add(
      () => new Promise((resolve, reject) => {
        client
          .mutate({
            variables: {
              regFormElementId,
              targetObjectId,
              attributes: attributesArray,
            },
            mutation: updateComponentAttrMutation,
          })
          .then((data) => {
            const {
              data: {
                regformBuilderObjectSetAttributes: { success },
              },
            } = data;
            attributes = [];
            setNetworkError(!success);
            if (callBack) {
              callBack(success);
            }
            resolve();
          }).catch(({ networkError, message }) => {
            attributes = [];
            if (networkError && networkError.statusCode !== 500) {
              setNetworkError(true);
            }
            if (callBack) {
              callBack(false);
            }
            // window.Sentry.captureException(message);
            reject(message);
          })
          .finally(() => {
            updateCurrentInQueue(
              queue.size + queue.pending,
            );
          });
      }),
    );
    updateCurrentInQueue(queue.size + queue.pending);
    setNetworkError(false);
  },
  1300,
);

const mainForm = {
  state: [],
  reducers: {
    addComponentToForm(state, newFormComponent, index) {
      const newState = _cloneDeep(state);
      if (index < 0) {
        newState.splice(
          newState.length,
          0,
          newFormComponent,
        );
      } else {
        newState.splice(index, 0, newFormComponent);
      }
      return newState;
    },
    rearrangeComponent(state, sourceIndex, destinationIndex) {
      const targetComponent = { ...state[sourceIndex] };
      const newState = [...state];
      newState.splice(sourceIndex, 1);
      newState.splice(destinationIndex, 0, targetComponent);
      return newState;
    },
    removeComponent(state, componentId) {
      const toRemoveComponnet = state.find((eachComponent) => eachComponent.id === componentId);
      let newState = _cloneDeep(state);
      newState = newState.filter(
        (eachComponent) => eachComponent.id !== componentId,
      );
      if (toRemoveComponnet.availableChoices) {
        const choices = toRemoveComponnet.availableChoices.choices.map(({ value }) => value);
        newState = newState.map((eachComponent) => {
          if (eachComponent.relatedOptions) {
            eachComponent.relatedOptions = eachComponent.relatedOptions.filter(
              (optionId) => !choices.includes(optionId),
            );
          }
          return eachComponent;
        });
      }
      return newState;
    },
    replaceComponent(state, payload) {
      const newState = state.map((eachComponent) => {
        if (eachComponent.id === payload.id) {
          return payload;
        }
        return eachComponent;
      });
      return newState;
    },
    updateComponentAttr(state, { targetObjectId, attrName, value }) {
      const newState = state.map((eachComponent) => {
        if (eachComponent.id === targetObjectId) {
          let newComponent = _cloneDeep(eachComponent);
          newComponent = {
            ...newComponent,
            [attrName]: value,
          };
          return newComponent;
        }
        return eachComponent;
      });
      return newState;
    },
    resetInputValue(state) {
      const newState = _cloneDeep(state);
      return newState.map(
        (eachComponent) => {
          if (Array.isArray(eachComponent.questionAnswer)) {
            eachComponent.questionAnswer = [];
          } else {
            eachComponent.questionAnswer = '';
          }
          eachComponent.errorMessage = '';
          return eachComponent;
        },
      );
    },
    resetForm() {
      return [];
    },
    removeRelatedOption(state, optionId) {
      const newState = _cloneDeep(state);
      return newState.map(
        (eachComponent) => {
          if (eachComponent.relatedOptions) {
            eachComponent.relatedOptions = eachComponent.relatedOptions.filter(
              (each) => each !== optionId,
            );
          }
          return eachComponent;
        },
      );
    },
  },
  effects: (dispatch) => ({
    handleComponentChange({
      targetObjectId, attrName, value, valueType,
    }, state) {
      if (valueType === FormValueType.COLLECTION && attrName === 'questionAnswer') {
        const { logicalConditions } = state;
        const component = state.mainForm.find(({ id }) => id === targetObjectId);
        const currentAnswer = component.questionAnswer || [];
        let chosenChoices = state.chosenChoices || [];
        chosenChoices = chosenChoices.filter(
          (choiceId) => !currentAnswer.includes(choiceId),
        );
        chosenChoices = chosenChoices.concat(value);
        chosenChoices = [...new Set(chosenChoices)];
        dispatch.chosenChoices.setChosenChoices(chosenChoices);
        currentAnswer.forEach((each) => {
          const conditions = logicalConditions.getIn([each]) || List();
          conditions.entrySeq().forEach(([, { targetElementId }]) => {
            const targetElement = state.mainForm.find(({ id }) => id === targetElementId);
            const relatedOptions = targetElement.relatedOptions || [];
            let shouldRender = true;
            if (relatedOptions.length > 0) {
              shouldRender = relatedOptions.some((choice) => chosenChoices.includes(choice));
            }
            if (!shouldRender) {
              let resetValueType = FormValueType.STRING;
              let resetValue = '';
              if (isMcQuestion(targetElement.typeName)) {
                resetValueType = FormValueType.COLLECTION;
                resetValue = [];
              }
              dispatch.mainForm.handleComponentChange({
                targetObjectId: targetElementId,
                attrName,
                value: resetValue,
                valueType: resetValueType,
              });
              dispatch.mainForm.handleComponentChange({
                targetObjectId: targetElementId,
                attrName: 'errorMessage',
                value: '',
              });
            }
          });
        });
      }
      dispatch.mainForm.updateComponentAttr({
        targetObjectId, attrName, value,
      });
    },
    removeFormComponent({ componentId, targetObjectId }, state) {
      const targetComponent = state.mainForm.find(
        (each) => each.id === componentId,
      );
      dispatch.component.resetIsDisable(targetComponent.typeName);
      dispatch.logicalConditions.removeConditionByComponent(targetComponent);
      dispatch.mainForm.removeComponent(componentId);
      dispatch.currentInQueue.deleteComponent({ componentId, targetObjectId });
    },
    fetchFormByPaginationQuery({
      queue,
      client,
      registrationFormId,
      after = '',
    }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .query({
              variables: { registrationFormId, first: PAGINATION_SIZE, after },
              query: fetchFormByPaginationQuery,
              fetchPolicy: 'no-cache',
            })
            .then(({ data: { node } }) => {
              const {
                mainForm: {
                  pages: {
                    nodes,
                  },
                },
              } = node;
              const {
                pageInfo: {
                  endCursor,
                  hasNextPage,
                },
                edges,
              } = nodes[0].elements;
              insertComponentToForm(
                edges,
                state.component,
                state.locale.code,
                state.languageList,
                dispatch.mainForm.addComponentToForm,
                dispatch.component.disableComponent,
              );
              dispatch.mainFormTotalCount.updateMainFormTotalCount('fetched', state.mainFormTotalCount.fetched + edges.length);
              if (hasNextPage) {
                dispatch.mainForm.fetchFormByPaginationQuery({
                  queue,
                  client,
                  registrationFormId,
                  after: endCursor,
                });
              }
              resolve();
            })
            .catch((error) => {
              console.log(error);
              dispatch.hasError.setHasError({ error: true, message: 'fetch_form_fail' });
              dispatch.showHelpButton.toggleShowHelpButton();
              // window.Sentry.captureException(error);
              reject();
            });
        }), {
          priority: 1,
        },
      );
    },
    fetchFormBuilder({ registrationFormId, queue, client }) {
      queue.addAll(
        [
          () => new Promise((resolve, reject) => {
            try {
              dispatch.isLoading.setIsLoading(true);
              dispatch.mainForm.resetForm();
              dispatch.mainForm.fetchFormByPaginationQuery({ queue, client, registrationFormId });
              resolve();
            } catch {
              dispatch.hasError.setHasError({ error: true, message: 'fetch_form_fail' });
              dispatch.showHelpButton.toggleShowHelpButton();

              reject();
            }
          }),
          () => new Promise((resolve, reject) => {
            client
              .query({
                variables: { registrationFormId },
                query: fetchFormLogicQuery,
                fetchPolicy: 'no-cache',
              })
              .then(({ data: { node } }) => {
                const {
                  logics: {
                    nodes: logicNodes,
                  },
                } = node;
                dispatch.logicalConditions.initializeByLogics(logicNodes);
                dispatch.mainFormTotalCount.updateMainFormTotalCount('fetched', 0);
                dispatch.isLoading.setIsLoading(false);
                resolve();
              })
              .catch((error) => {
                console.log(error);
                dispatch.hasError.setHasError({ error: true, message: 'fetch_logic_fail' });
                dispatch.showHelpButton.toggleShowHelpButton();
                // window.Sentry.captureException(error);
                reject();
              });
          }),
        ],
      );
    },
    insertComponent({
      source,
      newFormComponent,
      targetObjectId,
      typeName,
      attributes: componentAttributes,
      position,
      queue,
      client,
    }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                regFormElementId: state.regFormElementId,
                targetObjectId,
                position,
                typeName,
                attributes: componentAttributes,
              },
              mutation: insertComponentMutation,
            })
            .then((result) => {
              const {
                data: {
                  regformBuilderCollectionAddObject: { newObject, success },
                },
              } = result;
              dispatch.networkError.setNetworkError(!success);
              if (success) {
                dispatch.mainFormTotalCount.updateMainFormTotalCount('total', state.mainFormTotalCount.total + 1);
                dispatch.currentEditComponent.updateCurrentEditComponentId({
                  oldComponentId: newFormComponent.id,
                  newComponentId: newObject.id,
                });
                dispatch[source].updateComponentAttr({
                  targetObjectId: newFormComponent.id,
                  attrName: 'idUpdated',
                  value: true,
                });
                dispatch[source].updateComponentAttr({
                  targetObjectId: newFormComponent.id,
                  attrName: 'id',
                  value: newObject.id,
                });
              }
              resolve();
            })
            .catch((error) => {
              dispatch.networkError.setNetworkError(true);
              // window.Sentry.captureException(error);
              reject(error);
            })
            .finally(() => {
              dispatch.currentInQueue.updateCurrentInQueue(
                queue.size + queue.pending,
              );
            });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
    },
    deleteComponent({
      componentId,
      targetObjectId,
      queue,
      client,
    }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                regFormElementId: state.regFormElementId,
                targetObjectId,
                componentId,
              },
              mutation: deleteComponentMutation,
            })
            .then((data) => {
              const {
                data: {
                  regformBuilderCollectionRemoveObject: { success },
                },
              } = data;
              if (success) {
                dispatch.mainFormTotalCount.updateMainFormTotalCount('total', state.mainFormTotalCount.total - 1);
              }
              dispatch.networkError.setNetworkError(!success);
              resolve();
            })
            .catch((error) => {
              dispatch.networkError.setNetworkError(true);
              // window.Sentry.captureException(error);
              reject(error);
            })
            .finally(() => {
              dispatch.currentInQueue.updateCurrentInQueue(
                queue.size + queue.pending,
              );
            });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
    },
    mcQuestionAddOption({
      componentElementID, optionUUid, choices, queue, client,
    }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                regFormElementId: state.regFormElementId,
                locale: state.locale.code,
                componentElementID,
              },
              mutation: addOptionMutation,
            })
            .then((result) => {
              const {
                data: {
                  regformBuilderCollectionAddObject: { newObject, success },
                },
              } = result;
              dispatch.networkError.setNetworkError(!success);
              if (success) {
                if (state.currentEditComponent.id === componentElementID) {
                  dispatch.currentEditComponent.updateChoiceId(optionUUid, newObject.id);
                } else {
                  const newChoices = choices.map((eachChoice) => {
                    if (eachChoice.value === optionUUid) {
                      eachChoice = {
                        ...eachChoice,
                        value: newObject.id,
                      };
                    }
                    return eachChoice;
                  });
                  dispatch.currentEditComponent.updateComponent({
                    name: JSON.stringify({
                      attrName: 'availableChoices',
                    }),
                    value: { choices: newChoices },
                  });
                }
              }
              resolve();
            })
            .catch((error) => {
              dispatch.networkError.setNetworkError(true);
              // window.Sentry.captureException(error);
              reject(error);
            })
            .finally(() => {
              dispatch.currentInQueue.updateCurrentInQueue(
                queue.size + queue.pending,
              );
            });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
    },
    mcQuestionRemoveOption({
      optionId,
      targetObjectId,
      queue,
      client,
    }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                regFormElementId: state.regFormElementId,
                targetObjectId,
                optionId,
              },
              mutation: removeOptionMutation,
            })
            .then((data) => {
              const {
                data: {
                  regformBuilderCollectionRemoveObject: { success },
                },
              } = data;
              dispatch.networkError.setNetworkError(!success);
              dispatch.logicalConditions.removeConditionByOption(optionId);
              resolve();
            })
            .catch((error) => {
              dispatch.networkError.setNetworkError(true);
              // window.Sentry.captureException(error);
              reject(error);
            })
            .finally(() => {
              dispatch.currentInQueue.updateCurrentInQueue(
                queue.size + queue.pending,
              );
            });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
    },
    updateComponentAttrMutation({
      targetObjectId,
      attrName,
      localizable,
      valueType,
      value,
      callBack,
      byPassCurrentEditComponentChecking,
      queue,
      client,
    }, state) {
      if (state.currentEditComponent.idUpdated || byPassCurrentEditComponentChecking) {
        let newAttr = {
          name: attrName,
        };
        if (localizable) {
          newAttr = {
            ...newAttr,
            locale: state.locale.code,
          };
        }
        const mutationValue = {};
        switch (valueType) {
          case FormValueType.STRING:
            mutationValue.stringValue = value;
            break;
          case FormValueType.BOOLEAN:
            mutationValue.booleanValue = value;
            break;
          case FormValueType.FILE:
            mutationValue.fileValue = value;
            break;
          default:
        }
        newAttr = {
          ...newAttr,
          value: mutationValue,
        };
        const newAttrs = _cloneDeep(attributes);
        const temp = newAttrs.find((each) => each.name === attrName);
        if (typeof temp === 'undefined') {
          newAttrs.push(newAttr);
        } else {
          _merge(temp, newAttr);
        }
        attributes = newAttrs;
        debounceUpdateAttrMutation(
          targetObjectId,
          newAttrs,
          callBack,
          state.regFormElementId,
          dispatch.currentInQueue.updateCurrentInQueue,
          queue,
          client,
          dispatch.networkError.setNetworkError,
        );
      } else {
        dispatch.currentEditComponent.updateComponent({
          attrName: 'tempValue',
          value: {
            targetObjectId,
            attrName,
            localizable,
            valueType,
            value,
            callBack,
          },
        });
      }
    },
    forceUpdateAttrMutation() {
      debounceUpdateAttrMutation.flush();
    },
    reorderComponentMutation({
      componentId, position, targetObjectId, queue, client,
    }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                regFormElementId: state.regFormElementId,
                targetObjectId,
                componentId,
                position,
              },
              mutation: reorderComponentMutation,
            })
            .then((data) => {
              const {
                data: {
                  regformBuilderCollectionReorderObject: {
                    success, regform: { logics: { nodes } },
                  },
                },
              } = data;
              dispatch.logicalConditions.initializeByLogics(nodes);
              dispatch.networkError.setNetworkError(!success);
              resolve();
            })
            .catch((error) => {
              dispatch.networkError.setNetworkError(true);
              // window.Sentry.captureException(error);
              reject(error);
            })
            .finally(() => {
              dispatch.currentInQueue.updateCurrentInQueue(
                queue.size + queue.pending,
              );
            });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
    },
    purgeRegFormCache({ queue }, state) {
      const { regformUuid } = state;
      if (regformUuid) {
        queue.add(
          () => new Promise((resolve, reject) => {
            axios({
              url: `/api/v1/forms/${regformUuid}`,
              method: 'POST',
              data: {
                action: 'purge',
              },
            })
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                console.log(error);
                dispatch.networkError.setNetworkError(true);
                reject(error);
              });
          }),
        );
      }
    },
  }),
};

export default mainForm;
