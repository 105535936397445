import { gql } from '@apollo/client';

export default gql`
fragment McQuestionChoiceElement on RegFormMcQuestionChoiceElement {
    builderAttributes {
      name
      value {
        booleanValue
        decimalValue
        idValue
        intValue
        stringValue
      }
      valueType
    }
  }
`;
