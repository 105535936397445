const discountedPrice = {
  state: '0.00',
  reducers: {
    setDiscountedPrice(state, payload) {
      return payload;
    },
  },
};

export default discountedPrice;
