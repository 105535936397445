const showShareModal = {
  state: false,
  reducers: {
    setShowShareModal(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    toggleShareModal(result, state) {
      dispatch.showShareModal.setShowShareModal(!state.showShareModal);
    },
  }),
};
export default showShareModal;
