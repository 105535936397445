const formKind = {
  state: 'v3',
  reducers: {
    updateFormKind(state, payload) {
      return payload;
    },
  },
};

export default formKind;
