import { gql } from '@apollo/client';

const removeOptionMutation = gql`
  mutation removeOptionMutation(
    $optionId: String!
    $regFormElementId: ID!
    $targetObjectId: ID!
  ) {
    regformBuilderCollectionRemoveObject(
      input: {
        regformId: $regFormElementId
        targetObjectId: $targetObjectId
        attribute: "choices"
        removeObjectId: $optionId
      }
    ) {
      success
      errors {
        details {
          value
        }
        message
        path
        type
      }
      targetObject {
        builderAttributes {
          name
          value {
            collectionValue {
              nodes {
                id
                typeName: __typename
              }
            }
          }
        }
      }
      removeObject {
        typeName: __typename
        builderAttributes {
          name
        }
      }
    }
  }
`;

export default removeOptionMutation;
