import PQueue from 'p-queue';
import xtraClient from 'helpers/xtraApollo/client';

let client = xtraClient();

if (typeof window !== 'undefined') {
  const searchParams = new URLSearchParams(window.location.search);
  const accessToken = searchParams.get('personal_access_token');
  client = xtraClient(accessToken);
}

const queue = new PQueue({ concurrency: 1 });

const currentInQueue = {
  state: -1,
  reducers: {
    setCurrentInQueue(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    updateCurrentInQueue(payload) {
      dispatch.currentInQueue.setCurrentInQueue(payload);
    },
    fetchFormSettings() {
      dispatch.formSettings.fetchFormSettings({ queue, client });
    },
    fetchFormBuilder(registrationFormId) {
      dispatch.mainForm.fetchFormBuilder({ registrationFormId, queue, client });
    },
    fetchEventAndFormInfoQuery({ eventId, regFormId }) {
      dispatch.eventInfo.fetchEventAndFormInfoQuery({
        eventId,
        regFormId,
        queue,
        client,
      });
    },
    insertComponent({
      source,
      newFormComponent,
      targetObjectId,
      typeName,
      attributes,
      position,
    }) {
      dispatch.mainForm.insertComponent({
        source,
        newFormComponent,
        targetObjectId,
        typeName,
        attributes,
        position,
        queue,
        client,
      });
    },
    deleteComponent({ componentId, targetObjectId }) {
      dispatch.mainForm.deleteComponent({
        componentId,
        targetObjectId,
        queue,
        client,
      });
    },
    reorderComponentMutation(payload, state) {
      dispatch.mainForm.reorderComponentMutation(
        {
          ...payload,
          queue,
          client,
        },
        state,
      );
    },
    updateComponentAttrMutation(payload) {
      dispatch.mainForm.updateComponentAttrMutation({
        ...payload,
        queue,
        client,
      });
    },
    forceUpdateAttrMutation() {
      dispatch.mainForm.forceUpdateAttrMutation();
    },
    updateBackgroundColor(payload) {
      dispatch.backgroundColor.updateBackgroundColor({
        payload,
        queue,
        client,
      });
    },
    updateThemeColor(payload) {
      dispatch.themeColor.updateThemeColor({ payload, queue, client });
    },
    fetchTicketQuery() {
      dispatch.eventTicket.fetchTicketQuery({ queue, client });
    },
    insertTicket({ newTicket, position }) {
      dispatch.selectedTicket.insertTicket({
        newTicket, position, queue, client,
      });
    },
    reorderTicket({ ticketId, source, destination }) {
      dispatch.selectedTicket.reorderTicket({
        ticketId, source, destination, queue, client,
      });
    },
    deleteTicket({ ticketInfo, index }) {
      dispatch.selectedTicket.deleteTicket({
        ticketInfo, index, queue, client,
      });
    },
    mcQuestionAddOption({ componentElementID, choices, optionUUid }) {
      dispatch.mainForm.mcQuestionAddOption({
        componentElementID, choices, optionUUid, queue, client,
      });
    },
    mcQuestionRemoveOption({ optionId, targetObjectId }) {
      dispatch.mainForm.mcQuestionRemoveOption({
        optionId, targetObjectId, queue, client,
      });
    },
    reorderQuestionOptionMutation({ optionId, position, componentId }) {
      dispatch.currentEditComponent.reorderQuestionOptionMutation({
        optionId, position, componentId, queue, client,
      });
    },
    fetchLandingPageQuery(payload = false) {
      dispatch.landingPage.fetchLandingPageQuery({ payload, queue, client });
    },
    updateRegFormStatus(payload) {
      dispatch.regFormStatus.updateRegFormStatus({ payload, queue, client });
    },
    updateRegFormIncludeTicketing(payload) {
      dispatch.includeTicketing.updateRegFormIncludeTicketing({ payload, queue, client });
    },
    fetchThankYouPageQuery(payload = false) {
      dispatch.thankYouPage.fetchThankYouPageQuery({ payload, queue, client });
    },
    updateRegFormName(payload) {
      dispatch.regFormName.updateRegFormName({ payload, queue, client });
    },
    createLogicCondition({ targetObjectId, choiceId }) {
      dispatch.currentEditLogicalOption.createLogicCondition({
        targetObjectId, choiceId, queue, client,
      });
    },
    removeLogicCondition({ targetObjectId, optionId }) {
      dispatch.currentEditLogicalOption.removeLogicCondition({
        targetObjectId, optionId, queue, client,
      });
    },
    updatefontFamily(payload) {
      dispatch.fontFamily.updatefontFamily({ payload, queue, client });
    },
    updateFormSettingMutation() {
      dispatch.formSettings.updateFormSettingMutation({ queue, client });
    },
    fetchCustomDomainsByUser() {
      dispatch.customDomains.fetchCustomDomainsByUser({ queue });
    },
    fetchSelectedCustomDomainByUser() {
      dispatch.customDomains.fetchSelectedCustomDomainByUser({ queue });
    },
    createOrUpdateCustomDomain({ customDomain }) {
      dispatch.customDomains.createOrUpdateCustomDomain({ queue, customDomain });
    },
    deleteCustomDomain() {
      dispatch.customDomains.deleteCustomDomain({ queue });
    },
    purgeRegFormCache() {
      dispatch.mainForm.purgeRegFormCache({ queue });
    },
  }),
};
export default currentInQueue;
