const orderCreateTime = {
  state: Date.now(),
  reducers: {
    setOrderCreateTime(state, payload) {
      return payload;
    },
  },
};

export default orderCreateTime;
