const userIp = {
  state: null,
  reducers: {
    setUserIp(state, payload) {
      return payload;
    },
  },
};

export default userIp;
