import _cloneDeep from 'lodash/cloneDeep';
import { List } from 'immutable';

import {
  collectionAddObjectMutation,
  collectionRemoveObjectMutation,
} from '../Graphql';

const PresenceLogicType = 'RegformPresenceLogic';
const PresenceLogicAttributeName = 'logics';
const AddLogic = 'componentAddLogic';
const AnyCompoundConditionType = 'RegformAnyCompoundCondition';
const AnyCompoundConditionAttributeName = 'conditions';
const AddCompoundCondition = 'logicAddCompoundCondition';
const AtomicConditionType = 'RegformChoiceSelectedAtomicCondition';
const AtomicConditionAttributeName = 'subConditions';
const AddAtomicCondition = 'addAtomicCondition';
const RemoveAtomicCondition = 'removeAtomicCondition';

const defaultState = {
  choiceId: '',
  questionComponentId: '',
  availableOptions: [],
};

const currentEditLogicalOption = {
  state: defaultState,
  reducers: {
    updateOptionAttr(state, { attrName, value }) {
      let newState = _cloneDeep(state);
      newState = {
        ...newState,
        [attrName]: value,
      };
      return newState;
    },
    resetOptionAttr() {
      return defaultState;
    },
  },
  effects: (dispatch) => ({
    fetchLogicalConditions({
      targetObjectId, choiceId,
    }, state) {
      if (!state.preview) {
        dispatch.currentEditLogicalOption.updateOptionAttr(
          {
            attrName: 'choiceId',
            value: choiceId,
          },
        );
        dispatch.currentEditLogicalOption.updateOptionAttr(
          {
            attrName: 'questionComponentId',
            value: targetObjectId,
          },
        );
        const targetObjectIndex = state
          .mainForm
          .findIndex(({ id }) => id === targetObjectId);
        const availableComponents = state.mainForm.slice(targetObjectIndex + 1);
        const availableOptions = [];
        let conditions = state.logicalConditions.getIn([choiceId]) || List();
        conditions = conditions.toJS();
        availableComponents.forEach(({ id, labelText, labelTextPlaceholder }) => {
          const option = { label: labelText || labelTextPlaceholder, value: id, isSelected: false };
          const condition = conditions.find(
            ({ targetElementId }) => targetElementId === id,
          );
          if (condition) {
            option.isSelected = true;
            option.conditionId = condition.conditionId;
          }
          availableOptions.push(option);
        });
        dispatch.currentEditLogicalOption.updateOptionAttr(
          {
            attrName: 'availableOptions',
            value: availableOptions,
          },
        );
      }
    },
    createCollectionObject({
      objectType, targetObjectId, choiceId, queue, client,
    }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          let attribute;
          let attributes = [];
          let mutationName;
          switch (objectType) {
            case PresenceLogicType:
              attribute = PresenceLogicAttributeName;
              mutationName = AddLogic;
              break;
            case AnyCompoundConditionType:
              attribute = AnyCompoundConditionAttributeName;
              mutationName = AddCompoundCondition;
              break;
            case AtomicConditionType:
              attribute = AtomicConditionAttributeName;
              mutationName = AddAtomicCondition;
              attributes = [
                {
                  name: 'choiceElement',
                  value: {
                    idValue: choiceId,
                  },
                },
              ];
              break;
            default: break;
          }
          client
            .mutate({
              variables: {
                regFormElementId: state.regFormElementId,
                componentElementID: targetObjectId,
                newObjectType: objectType,
                attribute,
                attributes,
              },
              mutation: collectionAddObjectMutation(mutationName),
            })
            .then((result) => {
              const {
                data: {
                  regformBuilderCollectionAddObject: { newObject: { id: newObjectId }, success },
                },
              } = result;
              dispatch.networkError.setNetworkError(!success);
              if (success) {
                let componentObject;
                let conditions;
                let relatedOptions;
                switch (objectType) {
                  case PresenceLogicType:
                    dispatch.mainForm.updateComponentAttr({
                      targetObjectId,
                      attrName: 'logicId',
                      value: newObjectId,
                    });
                    attribute = PresenceLogicAttributeName;
                    dispatch.currentEditLogicalOption.createCollectionObject({
                      objectType: AnyCompoundConditionType,
                      targetObjectId: newObjectId,
                      choiceId,
                      queue,
                      client,
                    });
                    break;
                  case AnyCompoundConditionType:
                    componentObject = state.mainForm.find(
                      (component) => component.logicId === targetObjectId,
                    );
                    dispatch.mainForm.updateComponentAttr({
                      targetObjectId: componentObject.id,
                      attrName: 'compoundConditionId',
                      value: newObjectId,
                    });
                    attribute = AnyCompoundConditionAttributeName;
                    dispatch.currentEditLogicalOption.createCollectionObject({
                      objectType: AtomicConditionType,
                      targetObjectId: newObjectId,
                      choiceId,
                      queue,
                      client,
                    });
                    break;
                  case AtomicConditionType:
                    componentObject = state.mainForm.find(
                      (component) => component.compoundConditionId === targetObjectId,
                    );
                    conditions = state.logicalConditions.getIn([choiceId]) || List();
                    conditions = conditions.push({
                      conditionId: newObjectId,
                      targetElementId: componentObject.id,
                      labelText: componentObject.labelText,
                    });
                    dispatch.logicalConditions.setInLogicalConditions([choiceId], conditions);
                    relatedOptions = componentObject.relatedOptions || [];
                    relatedOptions.push(choiceId);
                    dispatch.mainForm.updateComponentAttr({
                      targetObjectId: componentObject.id,
                      attrName: 'relatedOptions',
                      value: relatedOptions,
                    });
                    dispatch.currentEditLogicalOption.fetchLogicalConditions({
                      targetObjectId: state.currentEditLogicalOption.questionComponentId,
                      choiceId: state.currentEditLogicalOption.choiceId,
                    });
                    break;
                  default:
                    break;
                }
              }
              resolve(result);
            })
            .catch((error) => {
              dispatch.networkError.setNetworkError(true);
              dispatch.currentEditLogicalOption.fetchLogicalConditions({
                targetObjectId: state.currentEditLogicalOption.questionComponentId,
                choiceId: state.currentEditLogicalOption.choiceId,
              });
              reject(error);
            })
            .finally(() => {
              dispatch.currentInQueue.updateCurrentInQueue(
                queue.size + queue.pending,
              );
            });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
      dispatch.networkError.setNetworkError(false);
    },
    createLogicCondition({
      targetObjectId: objectId, choiceId, queue, client,
    }, state) {
      queue.add(
        () => new Promise((resolve) => {
          const componentObject = state.mainForm.find((component) => component.id === objectId);
          const { compoundConditionId, logicId } = componentObject;
          let targetObjectId;
          let objectType;
          if (logicId) {
            if (compoundConditionId) {
              objectType = AtomicConditionType;
              targetObjectId = compoundConditionId;
            } else {
              objectType = AnyCompoundConditionType;
              targetObjectId = logicId;
            }
          } else {
            objectType = PresenceLogicType;
            targetObjectId = objectId;
          }
          dispatch.currentEditLogicalOption.createCollectionObject({
            objectType,
            targetObjectId,
            choiceId,
            queue,
            client,
          });
          resolve();
          dispatch.currentInQueue.updateCurrentInQueue(
            queue.size + queue.pending,
          );
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
      dispatch.networkError.setNetworkError(false);
    },
    removeLogicCondition({
      targetObjectId: objectId, optionId, queue, client,
    }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          const componentObject = state.mainForm.find((component) => component.id === objectId);
          const { compoundConditionId } = componentObject;
          client
            .mutate({
              variables: {
                regFormElementId: state.regFormElementId,
                targetObjectId: compoundConditionId,
                attribute: AtomicConditionAttributeName,
                optionId,
              },
              mutation: collectionRemoveObjectMutation(RemoveAtomicCondition),
            })
            .then((result) => {
              const {
                data: {
                  regformBuilderCollectionRemoveObject: { success },
                },
              } = result;
              if (success) {
                const { choiceId } = state.currentEditLogicalOption;
                let conditions = (state.logicalConditions.getIn([choiceId]) || List()).toJS();
                conditions = conditions.filter(({ conditionId }) => conditionId !== optionId);
                dispatch.logicalConditions.setInLogicalConditions([choiceId], List(conditions));
                let relatedOptions = componentObject.relatedOptions || [];
                relatedOptions = relatedOptions.filter((option) => choiceId !== option);
                dispatch.mainForm.updateComponentAttr({
                  targetObjectId: componentObject.id,
                  attrName: 'relatedOptions',
                  value: relatedOptions,
                });
              }
              dispatch.networkError.setNetworkError(!success);
              resolve(result);
            })
            .catch((error) => {
              dispatch.networkError.setNetworkError(true);
              reject(error);
            })
            .finally(() => {
              dispatch.currentEditLogicalOption.fetchLogicalConditions({
                targetObjectId: state.currentEditLogicalOption.questionComponentId,
                choiceId: state.currentEditLogicalOption.choiceId,
              });
              dispatch.currentInQueue.updateCurrentInQueue(
                queue.size + queue.pending,
              );
            });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
      dispatch.networkError.setNetworkError(false);
    },
  }),
};

export default currentEditLogicalOption;
