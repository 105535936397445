import { gql } from '@apollo/client';
import regformTicketFieldsFragment from './fragments/regformTicketFieldsFragment';

const fetchFormTicketQuery = gql`
  query fetchFormTicketQuery($regformId: ID!, $locale: LocaleEnum, $after: String, $first: Int) {
    formNode: node(id: $regformId) {
      ... on RegformV3Form {
        defaultTicket {
          ... regformTicketFields
        }
        ticketingElement {
          id
          ticketItems (after: $after, first: $first) {
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              node @locale(lang: $locale){
                id
                ticket {
                  ... regformTicketFields
                }
              }
            }
          }
        }
      }
    }
  }
  ${regformTicketFieldsFragment}
`;

export default fetchFormTicketQuery;
