import "scroll-behavior-polyfill";

interface ErrorElementData {
  fieldElementId: string;
  attendeeId: string,
}

const scrollToErrorElement = ({ fieldElementId, attendeeId }: ErrorElementData): void => {
  const scrollTo = fieldElementId + attendeeId;
  document
    .querySelector(`#Container${scrollTo}`)
    ?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
};

export default scrollToErrorElement;
