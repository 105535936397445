const ticketElementID = {
  state: '',
  reducers: {
    setTicketElementID(state, payload) {
      return payload;
    },
  },
};

export default ticketElementID;
