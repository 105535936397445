import { batch } from 'react-redux';

const preview = {
  state: false,
  reducers: {
    setPreviewMode(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    togglePreviewMode(result, state) {
      if (!state.preview) {
        dispatch.currentEditComponent.updateCurrentEditComponent({});
        dispatch.formStage.setFormStage(0);
      } else {
        batch(() => {
          dispatch.mainForm.resetInputValue();
          dispatch.chosenChoices.setChosenChoices([]);
          if (state.currentTab === 1) {
            dispatch.formStage.setFormStage(2);
          }
        });
      }
      dispatch.preview.setPreviewMode(!state.preview);
    },
  }),
};
export default preview;
