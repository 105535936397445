import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import Immutable, { List } from 'immutable';
import { v4 as uuidv4 } from 'uuid';
import RegformDisplayElement from 'schema/displayElement';
import FormValueType from 'schema/formValueType';

const rsvpNoThankYouPage = {
  state: Immutable.fromJS({}),
  reducers: {
    setPageInfo(state, field, value) {
      const newState = state;
      return newState.set(field, value);
    },
    addComponentToForm(state, newComponent, index) {
      let newState = state;
      let components = newState.get('components');
      components = components.insert(index, newComponent);
      newState = newState.set('components', components);
      return newState;
    },
    rearrangeComponent(state, sourceIndex, destinationIndex) {
      let newState = state;
      let components = newState.getIn(['components']);
      const targetComponent = components.get(sourceIndex);
      components = components.delete(sourceIndex);
      components = components.insert(destinationIndex, targetComponent);
      newState = newState.setIn(['components'], components);
      return newState;
    },
    updateComponentAttr(state, { targetObjectId, attrName, value }) {
      let newState = state;
      const components = newState.getIn(['components']);
      const index = components.findIndex((each) => each.id === targetObjectId);
      if (index !== -1) {
        newState = newState.setIn(['components', index, attrName], value);
      }
      return newState;
    },
    removeComponent(state, index) {
      let newState = state;
      let components = newState.getIn(['components']);
      components = components.delete(index);
      newState = newState.setIn(['components'], components);
      return newState;
    },
    replaceComponent(state, payload) {
      let newState = state;
      const components = newState.getIn(['components']);
      const index = components.findIndex((each) => each.id === payload.id);
      if (index !== -1) {
        newState = newState.setIn(['components', index], payload);
      }
      return newState;
    },
  },
  effects: (dispatch) => ({
    parsePageInfo(payload, state) {
      const { pages: { nodes } } = payload;
      const componentList = [];
      dispatch.rsvpNoThankYouPage.setPageInfo('id', nodes[0].id);
      nodes[0].elements[`${state.locale.code}`].forEach((each, elementIndex) => {
        let newComponent = _cloneDeep(
          state.component[each.typeName],
        );
        const {
          builderAttributes: componentBuilderAttributes,
          typeName,
        } = each;
        const newAttrs = {};
        componentBuilderAttributes.forEach((eachComponent, attrIndex) => {
          switch (eachComponent.valueType) {
            case FormValueType.STRING:
              if (eachComponent.value.stringValue) {
                newAttrs[eachComponent.name] = eachComponent.value.stringValue;
              } else {
                state.languageList.some((eachLanguage) => {
                  if (nodes[0].elements[`${eachLanguage.code}`][elementIndex].builderAttributes[attrIndex].value.stringValue) {
                    newAttrs[eachComponent.name] = nodes[0].elements[`${eachLanguage.code}`][elementIndex].builderAttributes[attrIndex].value.stringValue;
                    return true;
                  }
                  return false;
                });
              }
              break;
            case FormValueType.FILE:
              if (typeName === RegformDisplayElement.Image) {
                if (eachComponent.value.fileValue) {
                  newAttrs.url = eachComponent.value.fileValue.url;
                } else {
                  state.languageList.some((eachLanguage) => {
                    if (nodes[0].elements[`${eachLanguage.code}`][elementIndex].builderAttributes[attrIndex].value.fileValue) {
                      newAttrs.url = nodes[0].elements[`${eachLanguage.code}`][elementIndex].builderAttributes[attrIndex].value.fileValue.url;
                      return true;
                    }
                    return false;
                  });
                }
              }
              break;
            default:
              break;
          }
        });
        newComponent = {
          ...newComponent,
          id: each.id,
          typeName: each.typeName,
          idUpdated: true,
          keyId: uuidv4(),
        };
        _merge(newComponent, newAttrs);
        componentList.push(newComponent);
      });
      dispatch.rsvpNoThankYouPage.setPageInfo('components', List(componentList));
    },
    removeFormComponent({ componentId, targetObjectId, index }, state) {
      const targetComponent = state.rsvpNoThankYouPage.getIn(['components', index]);
      dispatch.component.resetIsDisable(targetComponent.typeName);
      dispatch.rsvpNoThankYouPage.removeComponent(index);
      dispatch.currentInQueue.deleteComponent({ componentId, targetObjectId });
    },
  }),
};

export default rsvpNoThankYouPage;
