const alreadyWarned = {
  state: false,
  reducers: {
    setAlreadyWarned(state, payload) {
      return payload;
    },
  },
};

export default alreadyWarned;
