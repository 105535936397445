import { gql } from '@apollo/client';

const orderInitiatePaymentV4 = gql`
  mutation orderInitiatePayment(
    $orderAccessKey: String!,
    $offlinePayment: Boolean!,
    $paymentMethod: OrderPaymentMethod,
    $successUrl: String,
    $cancelUrl: String,
    $locale: LocaleEnum = en,
  ) {
    orderInitiatePayment(
      input: { 
        orderAccessKey: $orderAccessKey,
        paymentMethod: $paymentMethod,
        successUrl: $successUrl,
        cancelUrl: $cancelUrl,
      }
    ) {
      errors {
        details {
          key
          value
        }
        message
        path
        type
      }
      success
      order {
        status
        regform @include(if: $offlinePayment) {
          eventBankAccountDetails @locale(lang: $locale)
        }
      }
      payment {
        uuid
        invoiceDueDate @include(if: $offlinePayment)
        transactionId
        status
        paymentGatewayResponse
      }
    }
  }
`;

export default orderInitiatePaymentV4;
