import _union from 'lodash/union';
import { v4 as uuidv4 } from 'uuid';
import { getOrderQuotation, applyDiscountCodeMutation, getAppliedDiscountCodes } from './Graphql';

const appliedDiscountCode = {
  state: [],
  reducers: {
    resetDiscountCode() {
      return [];
    },
    setDiscountCode(state, payload) {
      const applicableDiscountCode = payload.map(({ discountCode }) => (discountCode));
      return _union(applicableDiscountCode, state);
    },
  },
  effects: (dispatch) => ({
    fetchAppliedDiscountCodes({ queue, client }, state) {
      return queue.add(
        async () => {
          dispatch.isQuotatingPrice.setIsQuotatingPrice(true);
          dispatch.isSubmiting.setIsSubmiting(true);
          const appliedDiscountCodesResponse = await client
            .query({
              variables: {
                accessKey: state.orderAccessKey,
              },
              query: getAppliedDiscountCodes,
              fetchPolicy: 'no-cache',
            });
          const {
            appliedDiscountCodes: {
              nodes: appliedDiscountCodes,
            },
          } = appliedDiscountCodesResponse.data.orderByAccessKey;
          dispatch.appliedDiscountCode.setDiscountCode(appliedDiscountCodes);
          return appliedDiscountCodes;
        },
      );
    },
    getOrderQuotation({ queue, client, discountCode }, state) {
      const taskId = uuidv4();
      const TASK_TYPE = 'GET_QUOTATION';
      return queue.add(
        async () => {
          const ticketIds = state.ticket
            .reduce((acc, { id, selectedQuatity }) => {
              for (let i = 0; i < selectedQuatity; i += 1) {
                acc.push(id);
              }
              return acc;
            }, []);
          if (!ticketIds.length) {
            dispatch.actualPrice.setActualPrice('0.00');
            dispatch.discountedPrice.setDiscountedPrice('0.00');
            return;
          }
          dispatch.paymentPage.resetPaymentPage();
          dispatch.isQuotatingPrice.setIsQuotatingPrice(true);
          dispatch.isSubmiting.setIsSubmiting(true);
          dispatch.executingTasks.upsertExecutingTask({
            id: taskId,
            type: TASK_TYPE,
          });
          let discountCodes = state.discountCodes.map(
            ({ discountCode: eachCode }) => (eachCode),
          );
          if (discountCode) {
            discountCodes = [discountCode];
            dispatch.discountCodes.resetDiscountCode();
          }
          if (ticketIds || (discountCodes && discountCodes.length)) {
            try {
              const getOrderQuotationResponse = await client.query({
                variables: {
                  accessKey: state.orderAccessKey,
                  ticketIds,
                  discountCodes,
                  discountCode: discountCode || '',
                },
                query: getOrderQuotation,
                fetchPolicy: 'no-cache',
              });
              const {
                data: {
                  orderByAccessKey: {
                    discountCode: verifiedDiscountCode,
                    quotation: {
                      discountedAmount: {
                        amount,
                      },
                      actualPrice: {
                        amount: actualPriceAmount,
                      },
                      applicableDiscountOffers: {
                        nodes: applicableDiscountCode,
                      },
                    },
                  },
                },
              } = getOrderQuotationResponse;
              if (discountCode) {
                if (verifiedDiscountCode) {
                  if (verifiedDiscountCode.isAvailable) {
                    dispatch.discountCodes.setDiscountCode(verifiedDiscountCode);
                  } else {
                    dispatch.discountCodeError.setDiscountCodeError('regform_v3.error_messages.discount_code_out_of_availability_period');
                  }
                } else {
                  dispatch.discountCodeError.setDiscountCodeError('regform_v3.error_messages.not_applicable_to_form');
                }
              }
              if (discountCodes.length) {
                if (applicableDiscountCode.length) {
                  dispatch.discountCodeError.setDiscountCodeError('');
                } else {
                  dispatch.discountCodeError.setDiscountCodeError('regform_v3.error_messages.condition_not_match');
                }
              }
              dispatch.appliedDiscountCode.setDiscountCode(applicableDiscountCode);
              dispatch.discountedPrice.setDiscountedPrice(amount);
              dispatch.actualPrice.setActualPrice(actualPriceAmount);
            } catch (error) {
              console.error('Unable to apply discount code!');
              console.error(error);
              dispatch.discountCodeError.setDiscountCodeError('regform_v3.error_messages.discount_code_out_of_availability_period');
            }
          } else {
            dispatch.actualPrice.setActualPrice('0.00');
            dispatch.discountedPrice.setDiscountedPrice('0.00');
          }
          dispatch.executingTasks.removeExecutingTaskById(taskId);
          if (!state.executingTasks.typeCounts[TASK_TYPE]) {
            dispatch.isQuotatingPrice.setIsQuotatingPrice(false);
            dispatch.isSubmiting.setIsSubmiting(false);
          }
        },
      );
    },
    applyDiscountCode({ queue, client }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                orderAccessKey: state.orderAccessKey,
                discountCode: state.appliedDiscountCode[0],
              },
              mutation: applyDiscountCodeMutation,
            })
            .then((data) => {
              const {
                data: {
                  orderApplyDiscountCode: {
                    success,
                  },
                },
              } = data;
              if (success) {
                dispatch.currentInQueue.orderLockQuotaMutation();
              } else {
                dispatch.isSubmiting.setIsSubmiting(false);
                dispatch.appliedDiscountCode.resetDiscountCode();
              }
              console.log(data);
              resolve(success);
            })
            .catch((error) => {
              dispatch.formError.setFormError({ value: true, reason: 'ticket_error' });
              console.error('Unable to apply discount code!');
              console.error(error);
              reject(error);
            });
        }),
      );
    },
  }),
};

export default appliedDiscountCode;
