import { gql } from '@apollo/client';

const fetchOrderStatusQuery = gql`
  query getorderByAccessKey($orderAccessKey: ID!) {
    orderByAccessKey (accessKey: $orderAccessKey) {
      status
    }
  }
`;

export default fetchOrderStatusQuery;
