import { gql } from '@apollo/client';
import fragmentLogic from './fragments/fragmentLogic';

const reorderComponentMutation = gql`
  mutation reorderComponentMutation(
    $componentId: String!
    $position: Int!
    $regFormElementId: ID!
    $targetObjectId: ID!
  ) {
    regformBuilderCollectionReorderObject(
      input: {
        regformId: $regFormElementId
        targetObjectId: $targetObjectId
        attribute: "elements"
        reorderObjectId: $componentId
        position: $position
      }
    ) {
      success
      errors {
        details {
          value
        }
        message
        path
        type
      }
      targetObject {
        builderAttributes {
          name
          value {
            collectionValue {
              nodes {
                id
                typeName: __typename
              }
            }
          }
        }
      }
      reorderObject {
        typeName: __typename
        builderAttributes {
          name
        }
      }
      regform {
        ...LogicFragment
      }
    }
  }
  ${fragmentLogic}
`;
export default reorderComponentMutation;
