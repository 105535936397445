const initForm = {
  state: true,
  reducers: {
    setInitForm(state, payload) {
      return payload;
    }
  }
};

export default initForm;
