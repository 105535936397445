import { gql } from '@apollo/client';

const addTicketMutation = gql`
  mutation addTicketItem(
    $regformId: ID!,
    $ticketElementID: ID!,
    $ticketId: ID!,
    $position: Int!
  ) {
    regformBuilderCollectionAddObject(input: {
    regformId: $regformId,
    targetObjectId: $ticketElementID,
    newObjectType: "RegformTicketItemElement",
    attribute: "ticketItems",
    position: $position,
    attributes: [
      {
        name: "ticket",
        value: {
          idValue: $ticketId
        }
      }
    ]
  }) {
    success
    errors {
        details {
          value
        }
        message
        path
        type
      }
    newObject {
      id
      builderAttributes {
        name
        value {
          idValue
        }
      }
    }
    targetObject {
      builderAttributes {
        name
        value {
          collectionValue {
            nodes {
              builderAttributes {
                name
                value {
                  idValue
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export default addTicketMutation;
