const loadingSequnce = {
  state: [0, 1, 2, 3],
  reducers: {
    setLoadingSequnce(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    loadPages(_, state) {
      const newloadingSequnce = [...state.loadingSequnce].filter((i) => i !== state.formStage);
      newloadingSequnce.unshift(state.formStage);
      newloadingSequnce.forEach(i => {
        switch (i) {
          case 0:
            dispatch.currentInQueue.fetchLandingPageQuery();
            break;
          case 1:
            dispatch.currentInQueue.fetchTicketQuery();
            break;
          case 2:
            dispatch.currentInQueue.fetchFormBuilder(state.regFormElementId);
            break;
          case 3:
            dispatch.currentInQueue.fetchThankYouPageQuery();
            break;
          default:
            break;
        }
      });
    }
  }),
}

export default loadingSequnce;
