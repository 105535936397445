const landingPageId = {
  state: '',
  reducers: {
    setLandingPageId(state, payload) {
      return payload;
    },
  },
};

export default landingPageId;
