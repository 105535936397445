const wechatOpenId = {
  state: null,
  reducers: {
    setWechatOpenId(state, payload) {
      return payload;
    },
  },
};

export default wechatOpenId;
