import { gql } from '@apollo/client';

const setColorMutation = gql`
  mutation regformBuilderObjectSetAttributes(
    $regFormElementId: ID!
    $colorCode: String!
    $colorType: String!
  ) {
    regformBuilderObjectSetAttributes(
      input: {
        regformId: $regFormElementId
        targetObjectId: $regFormElementId
        attributes: [
          { name: $colorType, value: { stringValue: $colorCode } }
        ]
      }
    ) {
      success
      errors {
        details {
          value
        }
        message
        path
        type
      }
    }
  }
`;
export default setColorMutation;
