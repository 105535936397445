import { gql } from '@apollo/client';
import FragmentbuilderAttribute from './FragmentbuilderAttribute';

export default gql`
  fragment ElementNode on RegformBuilderObject {
    id
    typeName: __typename
    builderAttributes {
      ...BuilderAttribute
    }
    ... on RegformFieldElement {
      hintHtml
      hintEditorContent
      hintText
      labelText
      required
    }
    ... on RegformTextFieldElement {
      placeholderText
    }
    ... on RegformImageElement {
      url: imageUrl
    }
    ... on RegformTextElement {
      html
    }
    ... on RegformCheckboxQuestionFieldElement {
      checkedByDefault
    }
    ... on RegformWaiverQuestionFieldElement {
      checkedByDefault
      html
    }
    ... on RegformMcQuestionFieldElement {
      choices {
        nodes {
          id
          choiceText
        }
      }
    }
  }
  ${FragmentbuilderAttribute}
`;
