import { gql } from '@apollo/client';

const mutationStringWapper = (operationName) => (
  `mutation ${operationName}(
    $regFormElementId: ID!,
    $componentElementID: ID!,
    $newObjectType: String!,
    $attribute: String!,
    $attributes: [RegformBuilderAttributeInput!]!
  ) {
    regformBuilderCollectionAddObject(input: {
      regformId: $regFormElementId,
      targetObjectId: $componentElementID,
      newObjectType: $newObjectType,
      attribute: $attribute,
      attributes: $attributes
    }
  ) {
      success
      errors {
        details {
          value
        }
        message
        path
        type
      }
      newObject {
        id
        builderAttributes {
          name
          value {
            idValue
          }
        }
      }
      targetObject {
        builderAttributes {
          name
          value {
            collectionValue {
              nodes {
                builderAttributes {
                  name
                  value {
                    idValue
                  }
                }
              }
            }
          }
        }
      }
    }
  }`
);

const collectionAddObjectMutation = (operationName) => gql(mutationStringWapper(operationName));

export default collectionAddObjectMutation;
