export const emsMapping = {
  en: 'en',
  zh_hk: 'zh-HK',
  zh_cn: 'zh-CN',
  ja: 'ja',
  ko: 'ko',
  ru: 'ru',
};

export const emsToCodeMapping = {
  en: 'en',
  'zh-HK': 'zh_hk',
  'zh-CN': 'zh_cn',
  ja: 'ja',
  ko: 'ko',
  ru: 'ru',
};

export const langMapping = {
  en: 'English',
  zh_hk: '繁體中文',
  zh_cn: '简体中文',
  ja: '日本語',
  ko: '한국어',
  ru: 'русский',
};

const mapLang = (locales) => locales.map((eachLang) => ({
  code: eachLang,
  display: langMapping[eachLang],
  emsMapping: emsMapping[eachLang],
}));

const languageList = {
  state: [],
  reducers: {
    setLanuageList(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    updateLanguageList(locales) {
      dispatch.languageList.setLanuageList(mapLang(locales));
    },
  }),
};

export default languageList;
