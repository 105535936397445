const defaultTicket = {
  state: {},
  reducers: {
    setDefaultTicket(state, payload) {
      return payload;
    },
  },
};

export default defaultTicket;
