import { isBuiltInPaymentEnabled } from 'hooks/useBuiltInPaymentEnabled';

const formStage = {
  state: -1,
  reducers: {
    setFormStage(state, payload) {
      return payload;
    },
    setPreviousFormStage(state) {
      return state - 1;
    },
    setNextFormStage(state) {
      return state + 1;
    },
  },
  effects: (dispatch) => ({
    updateFormStage(payload) {
      dispatch.formStage.setFormStage(payload);
    },
    setStageToLanding: () => {
      dispatch.formStage.setFormStage(0);
    },
    setStageToTicketing: (_, state) => {
      if (state.includeTicketing) {
        dispatch.formStage.setFormStage(1);
      } else {
        throw new Error('Unable to set stage to ticketing since this form does not have any tickets.');
      }
    },
    setStageToForm: (_, state) => {
      const { includeTicketing } = state;
      dispatch.formStage.setFormStage(includeTicketing ? 2 : 1);
    },
    setStageToPayment: (_, state) => {
      const { includeTicketing, featureGuard, defaultTicket } = state;
      const builtInPaymentEnabled = isBuiltInPaymentEnabled({
        includeTicketing,
        enablePaymentSplit: featureGuard.regformV4Payment,
        defaultTicket,
      });

      if (!builtInPaymentEnabled) {
        throw new Error('Built in payment is not enabled, unable to set to review order page.');
      }

      dispatch.formStage.setFormStage(includeTicketing ? 3 : 2);
    },
    setStageToFinalize: (_, state) => {
      const { includeTicketing, featureGuard, defaultTicket } = state;
      const builtInPaymentIncrement = isBuiltInPaymentEnabled({
        includeTicketing,
        enablePaymentSplit: featureGuard.regformV4Payment,
        defaultTicket,
      }) ? 1 : 0;
      const baseStageNumber = (includeTicketing ? 3 : 2) + builtInPaymentIncrement;
      dispatch.formStage.setFormStage(baseStageNumber);
    },
  }),
};

export default formStage;
