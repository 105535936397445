const locale = {
  state: { code: 'en', display: 'English', emsMapping: 'emsMapping' },
  reducers: {
    setLocale(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    updateLocale(payload, state) {
      dispatch.locale.setLocale(payload);
      dispatch.currentEditComponent.setCurrentEditComponent({});
      dispatch.loadingSequnce.loadPages();
    },
  }),
};

export default locale;
