import set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';

const defaultPaymentPageAttributes = {
  paymentMethod: {
    value: null,
    error: null,
  },
  billingEmail: null,
  eventBankAccountDetails: '',
  mainPage: {
    error: null,
  },
  offlinePayment: {
    showModal: false,
    invoiceDueDate: null,
    paymentNonce: null,
  },
  paymentInfo: {
    paymentGatewayResponse: null,
    uuid: null,
  },
};

const paymentPage = {
  state: _cloneDeep(defaultPaymentPageAttributes),
  reducers: {
    resetPaymentPage() {
      return _cloneDeep(defaultPaymentPageAttributes);
    },
    setPaymentMethod(state, payload) {
      const paymentPageData = set({ ...state }, 'paymentMethod.value', payload);
      return paymentPageData;
    },
    setPaymentMethodError(state, payload) {
      const paymentPageData = set({ ...state }, 'paymentMethod.error', payload);
      return paymentPageData;
    },
    setPaymentPageError(state, payload) {
      const paymentPageData = set({ ...state }, 'mainPage.error', payload);
      return paymentPageData;
    },
    setBillingEmail(state, payload) {
      const paymentPageData = set({ ...state }, 'billingEmail', payload);
      return paymentPageData;
    },
    setBankAccountDetails(state, payload) {
      const paymentPageData = set({ ...state }, 'eventBankAccountDetails', payload);
      return paymentPageData;
    },
    setOfflinePaymentInvoiceDueDate(state, payload) {
      return set({ ...state }, 'offlinePayment.invoiceDueDate', payload);
    },
    setOfflinePaymentNonce(state, payload) {
      const paymentPageData = set({ ...state }, 'offlinePayment.paymentNonce', payload);
      return paymentPageData;
    },
    showOfflinePaymentModal(state) {
      const paymentPageData = set({ ...state }, 'offlinePayment.showModal', true);
      return paymentPageData;
    },
    hideOfflinePaymentModal(state) {
      let updatedState = { ...state };
      updatedState = set(updatedState, 'offlinePayment.showModal', false);
      return updatedState;
    },
    setPaymentInfo(state, payload) {
      return set(state, 'paymentInfo', payload);
    },
  },
};

export default paymentPage;
