const discountCodeEnabled = {
  state: false,
  reducers: {
    setDiscountCodeEnabled(state, payload) {
      return payload;
    },
  },
};

export default discountCodeEnabled;
