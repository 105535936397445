const showFormSettingsModal = {
  state: false,
  reducers: {
    setFormSettingsModal(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    toggleFormSettingsModal(result, state) {
      dispatch.showFormSettingsModal.setFormSettingsModal(!state.showFormSettingsModal);
    },
  }),
};

export default showFormSettingsModal;
