import { fetchThankYouPageQuery } from './Graphql';

const mapLang = (locales) => {
  const langMapping = {
    en: 'English',
    zh_hk: '繁體中文',
    zh_cn: '简体中文',
    ja: '日本語',
    ko: '한국어',
    ru: 'русский',
  };
  const emsMapping = {
    en: 'en',
    zh_hk: 'zh-HK',
    zh_cn: 'zh-CN',
    ja: 'ja',
    ko: 'ko',
    ru: 'ru',
  };
  return locales.map((eachLang) => ({
    code: eachLang,
    display: langMapping[eachLang],
    emsMapping: emsMapping[eachLang],
  }));
};

const thankYouPage = {
  state: [],
  reducers: {
    setThankYouPage(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    fetchThankYouPage({ client, queue }, state) {},
  }),
};

export default thankYouPage;
