const showHelpButton = {
  state: false,
  reducers: {
    toggleShowHelpButton(state) {
      return !state;
    },
  },
};

export default showHelpButton;
