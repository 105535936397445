const checkListWidgetShow = {
  state: false,
  reducers: {
    updateCheckListWidgetShow(state, payload) {
      return payload;
    },
  },
};

export default checkListWidgetShow;
