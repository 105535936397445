const placeholderLocation = {
  state: -1,
  reducers: {
    onDragUpdate(state, result) {
      const { destination, source } = result;
      if (
        destination !== null
        && source !== null
        && source.droppableId === destination.droppableId
      ) {
        return state;
      }

      if (destination !== null) {
        const { index } = destination;
        return index;
      }
      return -1;
    },
    resetPlaceholderLocation() {
      return -1;
    },
  },
};
export default placeholderLocation;
