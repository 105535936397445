const thankYouPageId = {
  state: '',
  reducers: {
    setThankYouPageId(state, payload) {
      return payload;
    },
  },
};

export default thankYouPageId;
