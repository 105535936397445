const eventInfo = {
  state: null,
  reducers: {
    setEventInfo(state, payload) {
      return payload;
    },
    setEventName(state, payload) {
      return {
        ...state,
        name: payload,
      };
    },
  },
};

export default eventInfo;
