import RegformFieldElement from 'schema/fieldElement';

const isMcQuestion = (
  typeName: RegformFieldElement,
): boolean => [
  RegformFieldElement.CheckboxMcQuestion,
  RegformFieldElement.RadioMcQuestion,
  RegformFieldElement.DropdownMcQuestion,
].includes(typeName);

export default isMcQuestion;
