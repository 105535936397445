import { sendGa } from 'helpers/ga';
import isAnyTicketPaid from 'helpers/isAnyTicketPaid';
import redirectToStripeCheckout from 'helpers/redirectToStripeCheckout';
import { isBuiltInPaymentEnabled } from 'hooks/useBuiltInPaymentEnabled';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import scrollToErrorElement from 'helpers/scrollToErrorElement';
import {
  orderLockQuota,
  orderInitiatePayment,
  orderFinalize,
  orderCancel,
  checkAttendeeError,
  checkOrderStatusQuery,
  orderOfflinePaymentConfirmMutation,
} from './Graphql';
import orderInitiatePaymentV4 from './Graphql/orderInitiatePaymentV4';
import orderSetLocale from './Graphql/orderSetLocale';
import fetchOrderStatusQuery from './Graphql/fetchOrderStatusQuery';

const whitelistedDomains = JSON.parse(process.env.NEXT_PUBLIC_EVENTX_REGFORM_DOMAINS);
const formDomain = whitelistedDomains.shift();

const orderAccessKey = {
  state: '',
  reducers: {
    setOrderAccessKey(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    createNewOrder(_, state) {
      dispatch.currentInQueue.initForm(state.orderStatus);
    },
    orderLockQuotaMutation({ queue, client }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                orderAccessKey: state.orderAccessKey,
              },
              mutation: orderLockQuota,
            })
            .then((data) => {
              const {
                data: {
                  orderLockQuota: {
                    success,
                    errors,
                    order: { expiresAt } = {},
                  },
                },
              } = data;
              dispatch.isSubmiting.setIsSubmiting(false);
              if (success) {
                const orderEndTime = (new Date(expiresAt)).getTime();
                dispatch.orderEndTime.setOrderEndTime(orderEndTime);
                dispatch.formStage.setStageToForm();
                dispatch.accessCode.saveAccessCodeToLocal();
              } else {
                dispatch.errorText.setErrorText(errors);
              }
              resolve();
            })
            .catch((error) => {
              dispatch.formError.setFormError({ value: true, reason: 'order_lock_fail' });
              console.error(error);
              reject(error);
            });
        }),
      );
    },
    orderOfflinePaymentConfirmMutation({ queue, client }, state) {
      queue.add(
        async () => {
          try {
            dispatch.isSubmiting.setIsSubmiting(true);
            const pendingPaymentsResponse = await client.mutate({
              variables: {
                orderAccessKey: state.orderAccessKey,
                paymentNonce: state.paymentPage.offlinePayment.paymentNonce,
              },
              mutation: orderOfflinePaymentConfirmMutation,
            });
            const {
              orderOfflinePaymentConfirm: {
                success, errors,
              },
            } = pendingPaymentsResponse.data;
            if (!success) {
              throw errors;
            } else {
              dispatch.formStage.setStageToFinalize();
            }
          } catch (e) {
            console.error(e);
            dispatch.paymentPage.setPaymentPageError('regform_v3.form_error.initiate_payment_fail.title');
          } finally {
            dispatch.isSubmiting.setIsSubmiting(false);
          }
        },
      );
    },
    orderInitiatePaymentV4Mutation({ queue, client }, state) {
      queue.add(
        async () => {
          try {
            dispatch.paymentPage.setPaymentPageError(null);
            dispatch.paymentPage.setPaymentMethodError(null);
            const isOrderFinalized = await dispatch.orderAccessKey.orderCheckFinalized({
              queue,
              client,
            });
            if (isOrderFinalized) {
              return;
            }
            dispatch.isSubmiting.setIsSubmiting(true);
            const {
              ticket,
              locale,
              userIp,
              wechatOpenId,
              regFormUuid,
              orderAccessKey: currentOrderAccessKey,
              locale: { code: localeCode },
              actualPrice,
              discountCodeEnabled,
            } = state;
            const hasPaidTickets = isAnyTicketPaid(
              ticket,
              actualPrice,
              locale,
              discountCodeEnabled,
            );

            if (hasPaidTickets && !state.paymentPage.paymentMethod.value) {
              dispatch.paymentPage.setPaymentMethodError('regsvp.error.please_select_one');
              return;
            }
            sendGa('Continue with Payment');

            const checkOrderStatusResponse = await client.mutate({
              variables: {
                accessKey: state.orderAccessKey,
              },
              mutation: checkOrderStatusQuery,
            });
            const {
              data: {
                orderByAccessKey: {
                  status: orderStatus,
                },
              },
            } = checkOrderStatusResponse;
            if (orderStatus === 'FINALIZED') {
              dispatch.formStage.setStageToFinalize();
            } else {
              const offlinePaymentMap = {
                bank_transfer: true,
                cheque: true,
              };
              const paymentMethod = state.paymentPage.paymentMethod.value;
              const isOfflinePayment = Boolean(offlinePaymentMap[(paymentMethod || '').toLowerCase()]);
              const successUrl = `${window.location.protocol}//${formDomain}/redirect/${regFormUuid}/${localeCode}/${currentOrderAccessKey}`;

              const cancelSearchParams = new URLSearchParams(window.location.search);
              cancelSearchParams.set('order_access_key', currentOrderAccessKey);
              cancelSearchParams.set('locale', localeCode);
              const cancelUrl = `${window.location.origin}${window.location.pathname}?${cancelSearchParams.toString()}`;
              const [initiatePaymentResponse] = await Promise.all([
                client.mutate({
                  variables: {
                    orderAccessKey: state.orderAccessKey,
                    paymentMethod: hasPaidTickets ? paymentMethod : undefined,
                    offlinePayment: isOfflinePayment,
                    successUrl: hasPaidTickets ? successUrl : undefined,
                    cancelUrl: hasPaidTickets ? cancelUrl : undefined,
                    locale: state.locale.code,
                  },
                  mutation: orderInitiatePaymentV4,
                }),
                client.mutate({
                  variables: {
                    orderAccessKey: state.orderAccessKey,
                    locale: state.locale.code,
                  },
                  mutation: orderSetLocale,
                }),
              ]);
              const {
                data: {
                  orderInitiatePayment: {
                    success,
                    errors,
                    payment: {
                      transactionId,
                      invoiceDueDate,
                      uuid,
                      status,
                    } = {},
                    order: {
                      regform: {
                        eventBankAccountDetails,
                      } = {},
                    } = {},
                  } = {},
                } = {},
              } = initiatePaymentResponse;
              if (!success) {
                throw errors;
              }
              if (status === 'FREE_ORDER') {
                dispatch.currentInQueue.orderFinalize();
                return;
              }
              if (isOfflinePayment) {
                const paymentNonce = btoa(uuid);
                dispatch.paymentPage.setOfflinePaymentInvoiceDueDate(invoiceDueDate);
                dispatch.paymentPage.setBankAccountDetails(eventBankAccountDetails);
                dispatch.paymentPage.setOfflinePaymentNonce(paymentNonce);
                dispatch.paymentPage.showOfflinePaymentModal();
              } else {
                await redirectToStripeCheckout(transactionId);
              }
            }
          } catch (error) {
            dispatch.paymentPage.setPaymentPageError('regform_v3.form_error.initiate_payment_fail.title');
            console.error(error);
          } finally {
            dispatch.isSubmiting.setIsSubmiting(false);
          }
        },
      );
    },
    orderInitiatePaymentMutation({ queue, client }, state) {
      queue.add(
        async () => {
          dispatch.formSubmitError.setFormSubmitError({ value: false, reason: '' });
          const isOrderFinalized = await dispatch.orderAccessKey.orderCheckFinalized({
            queue,
            client,
          });
          if (isOrderFinalized) {
            return;
          }
          dispatch.isSubmiting.setIsSubmiting(true);
          await client
            .mutate({
              variables: {
                orderAccessKey: state.orderAccessKey,
                locale: state.locale.code,
              },
              mutation: orderInitiatePayment,
            })
            .then((data) => {
              dispatch.isSubmiting.setIsSubmiting(false);
              const {
                data: {
                  orderInitiatePayment: {
                    success,
                    errors,
                    payment: {
                      status,
                      uuid,
                    },
                  },
                },
              } = data;
              if (success) {
                if (status === 'PENDING') {
                  window.location.href = `${process.env.NEXT_PUBLIC_XTRA_APP_URL}/registrations/${state.regFormUuid}/payment/${uuid}/edit?locale=${state.locale.code}`;
                } else {
                  dispatch.currentInQueue.orderFinalize();
                }
              } else {
                dispatch.formSubmitError.setFormSubmitError({ value: true, reason: 'initiate_payment_fail' });
                console.error(errors);
                throw errors;
              }
            })
            .catch((error) => {
              dispatch.isSubmiting.setIsSubmiting(false);
              dispatch.formSubmitError.setFormSubmitError({ value: true, reason: 'initiate_payment_fail' });
              console.error(error);
              throw error;
            });
        },
      );
    },
    async orderCheckFinalized({ queue, client }, state) {
      dispatch.isSubmiting.setIsSubmiting(true);
      try {
        const orderStatusResponse = await client
          .query({
            query: fetchOrderStatusQuery,
            variables: {
              orderAccessKey: state.orderAccessKey,
            },
            fetchPolicy: 'no-cache',
          });
        dispatch.isSubmiting.setIsSubmiting(false);
        const {
          data: {
            orderByAccessKey: { status: orderStatus },
          },
        } = orderStatusResponse;
        if (orderStatus === 'FINALIZED') {
          dispatch.formStage.setStageToFinalize();
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        dispatch.isSubmiting.setIsSubmiting(false);
        return false;
      }
    },
    orderFinalize({ queue, client }, state) {
      queue.add(
        async () => {
          dispatch.formSubmitError.setFormSubmitError({ value: false, reason: '' });
          const isOrderFinalized = await dispatch.orderAccessKey.orderCheckFinalized({
            queue,
            client,
          });
          if (isOrderFinalized) {
            return;
          }
          dispatch.isSubmiting.setIsSubmiting(true);
          client
            .mutate({
              variables: {
                orderAccessKey: state.orderAccessKey,
              },
              mutation: orderFinalize,
            })
            .then(({ data }) => {
              const {
                orderFinalize: {
                  success,
                  errors,
                },
              } = data;
              if (success) {
                dispatch.formStage.setStageToFinalize();
              } else {
                dispatch.formError.setFormError({ value: true, reason: 'order_finalize_fail' });
                dispatch.formSubmitError.setFormSubmitError({ value: true, reason: 'initiate_payment_fail' });
                // window.Sentry.captureException(errors);
                console.error(errors);
                throw errors;
              }
            })
            .catch((error) => {
              dispatch.formError.setFormError({ value: true, reason: 'order_finalize_fail' });
              dispatch.formSubmitError.setFormSubmitError({ value: true, reason: 'initiate_payment_fail' });
              // window.Sentry.captureException(error);
              console.error(error);
              throw error;
            }).finally(() => {
              dispatch.isSubmiting.setIsSubmiting(false);
            });
        },
      );
    },
    orderCancel({ client }, state) {
      client
        .mutate({
          variables: {
            orderAccessKey: state.orderAccessKey,
          },
          mutation: orderCancel,
        })
        .then(() => {
          console.log('canceled');
        });
    },
    rsvpOrderLookupOrCreate(_, state) {
      dispatch.currentInQueue.initForm(state.orderStatus);
    },
    rsvpOrderFinalize({ queue, client }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                orderAccessKey: state.orderAccessKey,
              },
              mutation: orderFinalize,
            })
            .then(({ data }) => {
              const {
                orderFinalize: {
                  success,
                  errors,
                },
              } = data;
              if (success) {
                dispatch.formStage.setStageToFinalize();
                resolve();
              } else {
                dispatch.formError.setFormError({ value: true, reason: 'order_finalize_fail' });
                // window.Sentry.captureException(errors);
                console.error(errors);
                reject(errors);
              }
            })
            .catch((error) => {
              dispatch.formError.setFormError({ value: true, reason: 'order_finalize_fail' });
              // window.Sentry.captureException(error);
              console.error(error);
              reject(error);
            });
        }),
      );
    },
    processToPayment({ queue, client }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          dispatch.formSubmitError.setFormSubmitError({ value: false, reason: '' });
          client
            .query({
              variables: {
                accessKey: state.orderAccessKey,
              },
              query: checkAttendeeError,
              fetchPolicy: 'no-cache',
            })
            .then(({ data }) => {
              const {
                orderByAccessKey: {
                  attendees: {
                    nodes,
                  },
                },
              } = data;
              const haveError = nodes.some((attendee) => (attendee.errors.length));
              if (haveError) {
                const errorMap = nodes
                  .filter((attendee) => !!(attendee.errors.length))
                  .reduce((acc, { attendeeId, errors }) => {
                    errors.forEach(({ fieldElementId, details: [{ value }] }) => {
                      _set(acc, `${attendeeId}.${fieldElementId}`, value);
                    });
                    return acc;
                  }, {});
                const { attendee: currentAttendees } = state;
                let errorFound = false;
                const attendeeWithErrors = _cloneDeep(currentAttendees).map((attendee) => {
                  const { attendeeId } = attendee;
                  return {
                    ...attendee,
                    mainForm: attendee.mainForm.map((element) => {
                      const { id: fieldElementId } = element;
                      if (!errorFound) {
                        scrollToErrorElement({ fieldElementId, attendeeId });
                        errorFound = true;
                      }
                      const errorValue = _get(errorMap, `${attendeeId}.${fieldElementId}`);
                      if (errorValue) {
                        return {
                          ...element,
                          errorMessage: `regsvp.error.${errorValue}`,
                        };
                      }
                      return element;
                    }),
                  };
                });
                dispatch.attendee.bulkUpdateAttendee(attendeeWithErrors);
                dispatch.isSubmiting.setIsSubmiting(false);
                dispatch.formSubmitError.setFormSubmitError({ value: true, reason: 'form_validation_fail' });
                console.error('Unable to validate form, please check the following values!');
                console.error(JSON.stringify(errorMap));
                reject(errorMap);
              } else {
                const { defaultTicket, includeTicketing, featureGuard } = state;
                const builtInPaymentEnabled = isBuiltInPaymentEnabled({
                  defaultTicket,
                  includeTicketing,
                  enablePaymentSplit: featureGuard.regformV4Payment,
                });
                dispatch.isSubmiting.setIsSubmiting(false);
                if (builtInPaymentEnabled) {
                  dispatch.formStage.setStageToPayment();
                } else {
                  dispatch.currentInQueue.orderInitiatePaymentMutation();
                }
                resolve();
              }
            })
            .catch((error) => {
              dispatch.formSubmitError.setFormSubmitError({ value: true, reason: 'process_payment_fail' });
              console.error('Unable to process to payment');
              dispatch.isSubmiting.setIsSubmiting(false);
              reject(error);
            });
        }),
      );
    },
  }),
};

export default orderAccessKey;
