import { gql } from '@apollo/client';
import fragmentMcQuestionElement from './fragmentMcQuestionElement';

export default gql`
fragment BuilderAttribute on RegformBuilderAttribute {
    localizable
    name
    value {
      booleanValue
      decimalValue
      idValue
      intValue
      stringValue
      fileValue {
        contentType
        fingerprint
        size
        url
      }
      collectionValue {
        nodes {
          id
          ...McQuestionChoiceElement
        }
      }
    }
    valueType
  }
  ${fragmentMcQuestionElement}
`;
