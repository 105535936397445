import { updateRegFormMutation } from '../Graphql';

const fontFamily = {
  state: 'Lato',
  reducers: {
    setFontFamily(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    updatefontFamily({ payload, queue, client }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                regFormElementId: state.regFormElementId,
                attributes: [{ name: 'fontFamily', value: { stringValue: payload } }],
              },
              mutation: updateRegFormMutation,
            })
            .then((data) => {
              const {
                data: {
                  regformBuilderObjectSetAttributes: { success },
                },
              } = data;
              dispatch.networkError.setNetworkError(!success);
              resolve();
            })
            .catch((error) => {
              dispatch.networkError.setNetworkError(true);
              // window.Sentry.captureException(error);
              reject();
            })
            .finally(() => {
              dispatch.currentInQueue.updateCurrentInQueue(
                queue.size + queue.pending,
              );
            });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
    },
  }),
};
export default fontFamily;
