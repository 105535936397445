import { gql } from '@apollo/client';
import regformTicketFieldsFragment from './fragments/regformTicketFieldsFragment';

const fetchTicketQuery = gql`
  query getBuilderTicketing($eventId: ID!, $regformId: ID!, $locale: LocaleEnum) {
    eventNode: node(id: $eventId) {
      ... on Event {
        tickets {
          nodes @locale(lang: $locale){
            ... regformTicketFields
          }
        }
      }
    }
    formNode: node(id: $regformId) {
      ... on RegformV3Form {
        defaultTicket {
          ... regformTicketFields
        }
        ticketingElement {
          id
          ticketItems {
            nodes @locale(lang: $locale){
              id
              ticket {
                ... regformTicketFields
              }
            }
          }
        }
      }
    }
  }
  ${regformTicketFieldsFragment}
`;

export default fetchTicketQuery;
