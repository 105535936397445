import { gql } from '@apollo/client';
import regformTicketFieldsFragment from './fragments/regformTicketFieldsFragment';

const fetchEventAndFormInfoQuery = gql`
  query fetchEventAndFormInfoQuery($eventId: ID!, $regFormId: ID!) {
    event: node(id: $eventId) {
      ... on Event {
        userUuid
        v2CustomDomainEnabled
        regformV4User
        plan
        customDomain
        databaseId
        name
        startAt
        endAt
        locales
        place
      }
    }
    form: node(id: $regFormId) {
      ... on RegformForm {
        name
        accessKey
        kind
        accessCodeEnabled 
      }
      ... on RegformV3Form {
        qrCodeUrl
        chinaHostQrCodeUrl
        includeTicketing
        fontFamily
        themeColor
        backgroundColor
        googleAnalyticsId
        googleTagManagerId
        mainForm {
          industriesOptions
          salutations
          countryCodes
          pages {
            nodes {
              id
              elements {
                totalCount
              }
            }
          }
        }
        defaultTicket {
          ... regformTicketFields
        }
      }
    }
  }
  ${regformTicketFieldsFragment}
`;

export default fetchEventAndFormInfoQuery;
