const orderStatus = {
  state: '',
  reducers: {
    setOrderStatus(state, payload) {
      return payload;
    },
  },
};

export default orderStatus;
