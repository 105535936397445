import { gql } from '@apollo/client';

const addOptionMutation = gql`
  mutation regformBuilderCollectionAddObject(
    $regFormElementId: ID!,
    $componentElementID: ID!,
    $locale: LocaleEnum!,
  ) {
    regformBuilderCollectionAddObject(input: {
    regformId: $regFormElementId,
    targetObjectId: $componentElementID,
    newObjectType: "RegFormMcQuestionChoiceElement",
    attribute: "choices",
    attributes: [
      {
        name: "choiceText",
        value: {
          stringValue: "New Option"
        }
        locale: $locale
      }
    ]
  }) {
    success
    errors {
        details {
          value
        }
        message
        path
        type
      }
    newObject {
      id
      builderAttributes {
        name
        value {
          idValue
        }
      }
    }
    targetObject {
      builderAttributes {
        name
        value {
          collectionValue {
            nodes {
              builderAttributes {
                name
                value {
                  idValue
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export default addOptionMutation;
