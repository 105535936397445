import { orderAttendeeAddTicket } from './Graphql';

const ticket = {
  state: [],
  reducers: {
    setTicket(state, payload) {
      return payload;
    },
    resetTicket(state) {
      const newState = state.map((eachTicket) => (
        {
          ...eachTicket,
          selectedQuatity: 0,
        }
      ));
      return newState;
    },
    updateTicket(state, { index, selectedQuatity }) {
      let updateTicketObject = state[index];
      updateTicketObject = {
        ...updateTicketObject,
        selectedQuatity,
      };
      const newState = [...state];
      newState.splice(index, 1);
      newState.splice(index, 0, updateTicketObject);
      return newState;
    },
  },
  effects: (dispatch) => ({
    addTicketMutation({
      queue,
      client,
      attendeeIds,
      ticketList,
    }, state) {
      return queue.add(
        () => new Promise((resolve, reject) => {
          const languageList = state.languageList.map(({ code }) => (code));
          client
            .mutate({
              mutation: orderAttendeeAddTicket(
                state.orderAccessKey,
                attendeeIds,
                ticketList,
                languageList,
              ),
              fetchPolicy: 'no-cache',
            })
            .then(({ data }) => {
              Object.keys(data).forEach((eachKey) => {
                const { attendee } = data[eachKey];
                dispatch.attendee.parseAttendeeObject(attendee);
              });
              resolve();
            })
            .catch((error) => {
              dispatch.formError.setFormError({ value: true, reason: 'add_ticket_fail' });
              console.error(error);
              reject(error);
            });
        }),
      );
    },
    unselectTicket() {
      dispatch.ticket.resetTicket();
      dispatch.actualPrice.setActualPrice('0.00');
      dispatch.discountedPrice.setDiscountedPrice('0.00');
    },
  }),
};

export default ticket;
