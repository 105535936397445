const isRegformV4User = {
  state: false,
  reducers: {
    setIsRegformV4User(state, payload) {
      return payload;
    },
  },
};

export default isRegformV4User;
