const ticketId = {
  state: '',
  reducers: {
    setTicketId(state, payload) {
      return payload;
    },
  },
};

export default ticketId;
