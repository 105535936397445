const pageElementId = {
  state: '',
  reducers: {
    updatePageElementId(state, payload) {
      return payload;
    },
  },
};

export default pageElementId;
