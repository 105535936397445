import { generate } from '@ant-design/colors';
import _debounce from 'lodash/debounce';
import { setColorMutation } from '../Graphql';

const debounceSetThemeMutation = _debounce(
  (colorCode, regFormElementId, updateCurrentInQueue, queue, client, setNetworkError) => {
    queue.add(
      () => new Promise((resolve, reject) => {
        client
          .mutate({
            variables: {
              regFormElementId,
              colorCode,
              colorType: 'themeColor',
            },
            mutation: setColorMutation,
          })
          .then((data) => {
            const {
              data: {
                regformBuilderObjectSetAttributes: { success },
              },
            } = data;
            setNetworkError(!success);
            resolve();
          })
          .catch((error) => {
            setNetworkError(true);
            // window.Sentry.captureException(error);
            reject();
          })
          .finally(() => {
            updateCurrentInQueue(
              queue.size + queue.pending,
            );
          });
      }),
    );
    updateCurrentInQueue(queue.size + queue.pending);
    setNetworkError(false);
  },
  1000,
);

const themeColor = {
  state: '#5c8df3',
  reducers: {
    setThemeColor(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    updateThemeColor({ payload, queue, client }, state) {
      dispatch.themeColor.setThemeColor(payload);
      debounceSetThemeMutation(
        payload,
        state.regFormElementId,
        dispatch.currentInQueue.updateCurrentInQueue,
        queue,
        client,
        dispatch.networkError.setNetworkError,
      );
      dispatch.currentInQueue.updateBackgroundColor(generate(payload)[1]);
    },
  }),
};

export default themeColor;
