import { gql } from '@apollo/client';

const updateRegFormMutation = gql`
  mutation regformBuilderObjectSetAttributes(
    $regFormElementId: ID!
    $attributes: [RegformBuilderAttributeInput!]!
  ) {
    regformBuilderObjectSetAttributes(
      input: {
        regformId: $regFormElementId
        targetObjectId: $regFormElementId
        attributes: $attributes
      }
    ) {
      success
      errors {
        details {
          value
        }
        message
        path
        type
      }
    }
  }
`;
export default updateRegFormMutation;
