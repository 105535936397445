import { gql } from '@apollo/client';

const removeTicketMutation = gql`
mutation removeTicketItem($regformId: ID!, $ticketElementID: ID!, $ticketId: String!) {
  regformBuilderCollectionRemoveObject(input: {
    regformId: $regformId,
    targetObjectId: $ticketElementID,
    attribute: "ticketItems",
    removeObjectId: $ticketId
  }) {
    errors {
      details {
        key
        value
      }
      message
      path
      type
    }
    success
    removeObject {
      id
      builderAttributes {
        name
        value {
          idValue
        }
      }
    }
    targetObject {
      builderAttributes {
        name
        value {
          collectionValue {
            nodes {
              builderAttributes {
                name
                value {
                  idValue
                }
                valueType
              }
                        }
          }
        }
      }
    }
  }
}`;

export default removeTicketMutation;
