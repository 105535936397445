import { createModel } from '@rematch/core';

interface ExecutingTask {
  id: string;
  type: string;
}

interface ExecutingTaskState {
  executingTasks: {
    [taskId: string]: ExecutingTask;
  };
  typeCounts: {
    [typeName: string]: number;
  };
}

const executingTasks = createModel({
  state: {
    executingTasks: {},
    typeCounts: {},
  },
  reducers: {
    upsertExecutingTask(state: ExecutingTaskState, payload: ExecutingTask): ExecutingTaskState {
      const updatedState = { ...state };
      updatedState.executingTasks[payload.id] = payload;
      if (!(payload.type in state.typeCounts)) {
        updatedState.typeCounts[payload.type] = 1;
      } else {
        updatedState.typeCounts[payload.type] += 1;
      }
      return updatedState;
    },
    removeExecutingTaskById(state: ExecutingTaskState, id: string): ExecutingTaskState {
      const updatedState = { ...state };
      if (id in updatedState.executingTasks) {
        const executingTask = state.executingTasks[id];
        updatedState.typeCounts[executingTask.type] -= 1;
        if (updatedState.typeCounts[executingTask.type] === 0) {
          delete updatedState.typeCounts[executingTask.type];
        }
        delete updatedState.executingTasks[id];
      }
      return updatedState;
    },
  },
});

export default executingTasks;
