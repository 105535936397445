const largestFormStage = {
  state: 0,
  reducers: {
    setLargestFormStage(state, payload) {
      return payload;
    },
  },
};

export default largestFormStage;
