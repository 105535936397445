import { gql } from '@apollo/client';

export default gql`
  fragment regformTicketFields on Ticket {
    id
    name
    details
    name
    price {
      amount
      currencyCode
    }
    quota
    remainingQuota
    salesStartAt
    salesEndAt
    isDefaultTicket
    allowZeroQuantity
  }
`;
