import _cloneDeep from 'lodash/cloneDeep';
import RegformFieldElement from 'schema/fieldElement';
import { v4 as uuidv4 } from 'uuid';

const getObjectIdByKeyId = (componentList, targetObjectId) => (
  componentList.find((eachComponent) => eachComponent.keyId === targetObjectId).id
);

const column = {
  state: {},
  reducers: {
    initColumn(state, columns) {
      return columns;
    },
  },
  effects: (dispatch) => ({
    onDragEnd(result, state) {
      const { draggableId, destination, source } = result;
      dispatch.placeholderLocation.resetPlaceholderLocation();
      if (destination !== null) {
        if (draggableId.split('@')[0] === 'questionOption') {
          dispatch.currentEditComponent.reorderQuestionOption({
            destination: destination.index,
            source: source.index,
          });
          dispatch.currentInQueue.reorderQuestionOptionMutation({
            optionId: draggableId.split('@')[1],
            position: destination.index,
            componentId: source.droppableId,
          });
        } else if (destination.droppableId === 'ticketEditor') {
          if (destination.droppableId === source.droppableId) {
            dispatch.selectedTicket.rearrangeTicket(
              source.index,
              destination.index,
            );
            dispatch.currentInQueue.reorderTicket({
              ticketId: draggableId,
              source: source.index,
              destination: destination.index,
            });
          } else {
            const ticket = _cloneDeep(state.eventTicket[draggableId]);
            const newTicket = { id: uuidv4(), ticket };
            dispatch.eventTicket.setTicketDisable(draggableId, true);
            dispatch.selectedTicket.addTicketToForm({ newTicket, position: destination.index });
            dispatch.currentInQueue.insertTicket({
              newTicket,
              position: destination.index,
            });
          }
        } else {
          const targetContainerType = destination.droppableId;
          let targetObjectId;
          let components;
          switch (targetContainerType) {
            case 'rsvpNoThankYouPage':
              targetObjectId = state.rsvpNoThankYouPage.get('id');
              components = state.rsvpNoThankYouPage.get('components').toJS();
              break;
            case 'mainForm':
              targetObjectId = state.pageElementId;
              components = state[targetContainerType];
              break;
            default:
              targetObjectId = state[`${targetContainerType}Id`];
              components = state[targetContainerType];
              break;
          }
          if (destination.droppableId === source.droppableId) {
            dispatch[targetContainerType].rearrangeComponent(
              source.index,
              destination.index,
            );
            dispatch.currentInQueue.reorderComponentMutation({
              componentId: getObjectIdByKeyId(components, draggableId),
              position: destination.index,
              targetObjectId,
            });
          } else {
            const newFormComponent = _cloneDeep(state.component[draggableId]);
            newFormComponent.id = uuidv4();
            newFormComponent.idUpdated = false;
            newFormComponent.keyId = uuidv4();
            newFormComponent.labelText = newFormComponent[`labelText_${state.locale.code}`];
            newFormComponent.hintText = newFormComponent[`hintText_${state.locale.code}`] || '';
            let newFormComponentAttrs;
            if (newFormComponent.type === 'CustomQuestion') {
              newFormComponentAttrs = [{
                name: 'labelText',
                value: {
                  stringValue: newFormComponent.labelText,
                },
                locale: state.locale.code,
              }];
              if (newFormComponent.typeName === RegformFieldElement.WaiverQuestion) {
                newFormComponentAttrs = [
                  ...newFormComponentAttrs,
                  {
                    name: 'required',
                    value: {
                      booleanValue: true,
                    },
                  },
                ];
              }
            } else {
              newFormComponentAttrs = [];
            }
            dispatch[targetContainerType].addComponentToForm(
              newFormComponent,
              destination.index,
            );
            dispatch.currentEditComponent.updateCurrentEditComponent({
              formStage: state.formStage,
              targetComponent: newFormComponent,
            });
            dispatch.currentInQueue.insertComponent({
              source: targetContainerType,
              newFormComponent,
              targetObjectId,
              typeName: newFormComponent.typeName,
              position: destination.index,
              attributes: newFormComponentAttrs,
            });
            if (!newFormComponent.type) {
              dispatch.component.disableComponent(draggableId);
            }
          }
        }
      }
    },
  }),
};

export default column;
