const landingPage = {
  state: [],
  reducers: {
    setLandingPage(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    async processToNextState(payload, state) {
      const { accessCode: { isEnabled } } = state;
      let process = true;
      if (isEnabled) {
        process = await dispatch.currentInQueue.validateAccessCode();
      }
      if (process) {
        const { needRefetch, attendee, includeTicketing } = state;
        if (needRefetch && typeof window !== 'undefined') {
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set('refetch', 'true');
          window.history.pushState(null, 'EventX', `?${searchParams.toString()}`);
          window.location.reload();
        } else if (attendee.length) {
          dispatch.formStage.setStageToForm();
        } else if (includeTicketing) {
          dispatch.formStage.setStageToTicketing();
        } else {
          dispatch.isSubmiting.setIsSubmiting(true);
          dispatch.attendee.addTicketToAttendee();
        }
      }
    },
  }),
};

export default landingPage;
