import i18n from '../../i18n/index';
import { emsToCodeMapping, langMapping } from './languageList';

const locale = {
  state: { code: emsToCodeMapping[i18n.language] || 'en', display: langMapping[emsToCodeMapping[i18n.language]] || 'Locale', emsMapping: i18n.language || 'en' },
  reducers: {
    setLocale(state, payload) {
      setTimeout(() => i18n.changeLanguage(payload.emsMapping));
      return payload;
    },
  },
  effects: (dispatch) => ({
    updateLocale(payload) {
      dispatch.locale.setLocale(payload);
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get('locale') !== payload.code) {
        searchParams.set('locale', payload.code);
        window.history.pushState(null, 'EventX', `?${searchParams.toString()}`);
      }
    },
  }),
};

export default locale;
