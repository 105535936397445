const userLocation = {
  state: '',
  reducers: {
    setUserLocation(state, payload) {
      return payload;
    },
  },
};

export default userLocation;
