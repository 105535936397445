const languageList = {
  state: [],
  reducers: {
    setLanuageList(state, payload) {
      return payload;
    },
  },
};

export default languageList;
