const formStage = {
  state: 0,
  reducers: {
    setFormStage(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    switchFormStage(payload, state) {
      if (state.currentEditComponent) {
        dispatch.currentEditComponent.updateCurrentEditComponent({ formStage: state.formStage });
      }
      sessionStorage.setItem(`formStage:${state.regFormId}`, payload);
      dispatch.formStage.setFormStage(payload);
    },
  }),
};

export default formStage;
