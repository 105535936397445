import { updateRegFormMutation } from '../Graphql';

const includeTicketing = {
  state: false,
  reducers: {
    setIncludeTicketing(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    updateRegFormIncludeTicketing({ payload, queue, client }, state) {
      if (state.defaultTicket) {
        dispatch.includeTicketing.setIncludeTicketing(payload);
      }
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                regFormElementId: state.regFormElementId,
                attributes: [{ name: 'includeTicketing', value: { booleanValue: payload } }],
              },
              mutation: updateRegFormMutation,
            })
            .then((data) => {
              const {
                data: {
                  regformBuilderObjectSetAttributes: { success, targetObject: { defaultTicket } },
                },
              } = data;
              dispatch.networkError.setNetworkError(!success);
              if (success) {
                dispatch.defaultTicket.setDefaultTicket(defaultTicket);
                dispatch.includeTicketing.setIncludeTicketing(payload);
              } else {
                dispatch.includeTicketing.setIncludeTicketing(!payload);
              }
              resolve();
            })
            .catch(() => {
              reject();
            })
            .finally(() => {
              dispatch.currentInQueue.updateCurrentInQueue(
                queue.size + queue.pending,
              );
            });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
    },
  }),
};

export default includeTicketing;
