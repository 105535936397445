const salutations = {
  state: {},
  reducers: {
    setSalutations(state, payload) {
      return payload;
    },
  },
};

export default salutations;
