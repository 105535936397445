const userUuid = {
  state: 0,
  reducers: {
    setUserUuid(state, payload) {
      return payload;
    },
  },
};

export default userUuid;
