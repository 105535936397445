const hasError = {
  state: {
    error: false,
    message: ''
  },
  reducers: {
    setHasError(state, payload) {
      return payload;
    },
  },
};

export default hasError;
