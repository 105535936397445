/* eslint-disable no-undef */
import axios from 'axios';

const ogInformation = {
  state: null,
  reducers: {
    setOgInformation(state, payload) {
      return payload;
    },
  },
  effects: () => ({
    updateWeChatShare(payload, state) {
      const shareUrl = window.location.href.split('#')[0];
      const signatureUrl = `${process.env.NEXT_PUBLIC_XTRA_APP_URL}/wechat/wechat_signature`;

      const data = { share_url: encodeURIComponent(shareUrl) };

      axios.post(signatureUrl, data).then((response) => {
        const {
          data: {
            success,
            signature,
            timestamp,
            appId,
            nonceStr,
          },
        } = response;
        if (success) {
          wx.config({
            debug: process.env.NODE_ENV !== 'production',
            appId,
            timestamp,
            nonceStr,
            signature,
            jsApiList: [
              'updateAppMessageShareData',
            ],
          });

          wx.ready(() => {
            wx.updateAppMessageShareData({
              title: state.eventInfo.name,
              desc: state.ogInformation.wechatShareDescription[state.locale.emsMapping],
              link: shareUrl,
              imgUrl: state.ogInformation.ogImageUrl,
            });
          });
        }
      }).catch((e) => {
        console.log('Unable to update WeChat app share message!');
        console.error(e);
      });
    },
  }),
};

export default ogInformation;
