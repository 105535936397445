import { gql } from '@apollo/client';

const fetchFormQuery = gql`
  query fetchFormQueryMultiLocale($registrationFormId: ID!) {
    node(id: $registrationFormId) {
      ... on RegformV3Form {
        mainForm {
          pages {
            nodes {
              id
              elements {
                en: nodes @locale(lang: en) {
                  ...ElementNode
                }
                zh_hk: nodes @locale(lang: zh_hk) {
                  ...ElementNode
                }
                zh_cn: nodes @locale(lang: zh_cn) {
                  ...ElementNode
                }
                ja: nodes @locale(lang: ja) {
                  ...ElementNode
                }
                ko: nodes @locale(lang: ko) {
                  ...ElementNode
                }
                ru: nodes @locale(lang: ru) {
                  ...ElementNode
                }
              }
            }
          }
        }
      }
    }
  }

  fragment ElementNode on RegformBuilderObject {
    id
    typeName: __typename
    builderAttributes {
      ...BuilderAttribute
    }
    ... on RegformFieldElement {
      hintText
      hintHtml
      hintEditorContent
      labelText
      required
    }
    ... on RegformTextFieldElement {
      placeholderText
    }
    ... on RegformImageElement {
      url: imageUrl
    }
    ... on RegformTextElement {
      html
    }
    ... on RegformCheckboxQuestionFieldElement {
      checkedByDefault
    }
    ... on RegformWaiverQuestionFieldElement {
      checkedByDefault
      html
    }
    ... on RegformMcQuestionFieldElement {
      choices {
        nodes {
          id
          choiceText
        }
      }
    }
  }

  fragment BuilderAttribute on RegformBuilderAttribute {
    localizable
    name
    value {
      booleanValue
      decimalValue
      idValue
      intValue
      stringValue
      fileValue {
        contentType
        fingerprint
        size
        url
      }
      collectionValue {
        nodes {
          id
          ...McQuestionChoiceElement
        }
      }
    }
    valueType
  }

  fragment McQuestionChoiceElement on RegFormMcQuestionChoiceElement {
    builderAttributes {
      name
      value {
        booleanValue
        decimalValue
        idValue
        intValue
        stringValue
      }
      valueType
    }
  }
`;

export default fetchFormQuery;
