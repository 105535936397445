const accessCodeEnabled = {
  state: true,
  reducers: {
    setAccessCodeEnabled(state, payload) {
      return payload;
    },
  },
};

export default accessCodeEnabled;
