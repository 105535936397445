const errorText = {
  state: [],
  reducers: {
    setErrorText(state, payload) {
      return payload;
    },
  },
};

export default errorText;
