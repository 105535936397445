import { loadStripe, StripeError } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_CLIENT_API_KEY);

const redirectToStripeCheckout = async (sessionId: string): Promise<{
  error: StripeError;
}> => {
  const stripe = await stripePromise;

  const result = await stripe.redirectToCheckout({
    sessionId,
  });

  if (result.error) {
    throw result.error;
  }

  return result;
};

export default redirectToStripeCheckout;
