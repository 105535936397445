const formError = {
  state: { value: false, reason: '' },
  reducers: {
    setFormError(state, payload) {
      return payload;
    },
    setOrderExpired(state) {
      return { value: true, reason: 'order_expired' };
    },
  },
};

export default formError;
