const footerColorMode = {
  state: 'black',
  reducers: {
    setFooterColorModeByColor(state, color) {
      let tuneColor = color.slice(1);
      const r = parseInt(tuneColor.substr(0, 2), 16);
      const g = parseInt(tuneColor.substr(2, 2), 16);
      const b = parseInt(tuneColor.substr(4, 2), 16);
      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      tuneColor = (yiq >= 135) ? 'black' : 'white';
      return tuneColor;
    },
  },
};
export default footerColorMode;
