const isQuotatingPrice = {
  state: false,
  reducers: {
    setIsQuotatingPrice(state, payload) {
      return payload;
    },
  },
};

export default isQuotatingPrice;
