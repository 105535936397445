import { fetchEventAndFormInfoQuery } from '../Graphql';

const mapLang = (locales) => {
  const langMapping = {
    en: 'English',
    zh_hk: '繁體中文',
    zh_cn: '简体中文',
    ja: '日本語',
    ko: '한국어',
    ru: 'русский',
  };
  const emsMapping = {
    en: 'en',
    zh_hk: 'zh-HK',
    zh_cn: 'zh-CN',
    ja: 'ja',
    ko: 'ko',
    ru: 'ru',
  };
  return locales.map((eachLang) => ({
    code: eachLang,
    display: langMapping[eachLang],
    emsMapping: emsMapping[eachLang],
  }));
};

const eventInfo = {
  state: {
    partyId: 0,
    name: '',
    startAt: '',
    endAt: '',
    place: '',
  },
  reducers: {
    setEventInfo(state, {
      partyId, name, startAt, endAt, place,
    }) {
      return {
        partyId, name, startAt, endAt, place,
      };
    },
  },
  effects: (dispatch) => ({
    fetchEventAndFormInfoQuery({
      eventId,
      regFormId,
      queue,
      client,
    }) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .query({
              variables: {
                eventId,
                regFormId,
              },
              fetchPolicy: 'no-cache',
              query: fetchEventAndFormInfoQuery,
            })
            .then(({ data }) => {
              const {
                event: {
                  userUuid,
                  v2CustomDomainEnabled,
                  databaseId: partyId,
                  name,
                  startAt,
                  endAt,
                  locales,
                  regformV4User,
                  plan,
                  place,
                },
                form: {
                  name: formName,
                  accessKey,
                  kind,
                  accessCodeEnabled,
                  qrCodeUrl,
                  chinaHostQrCodeUrl,
                  includeTicketing,
                  fontFamily,
                  themeColor,
                  backgroundColor,
                  googleAnalyticsId,
                  googleTagManagerId,
                  defaultTicket,
                  mainForm: {
                    industriesOptions,
                    salutations,
                    countryCodes,
                    pages: {
                      nodes,
                    },
                  },
                },
              } = data;
              dispatch.eventInfo.setEventInfo({
                partyId,
                name,
                startAt,
                endAt,
                place,
              });
              dispatch.userUuid.setUserUuid(userUuid);
              dispatch.customDomainEnabled.setCustomDomainEnabled(v2CustomDomainEnabled);
              dispatch.subscriptionPlan.updateSubscriptionPlan(plan);
              dispatch.isRegformV4User.setIsRegformV4User(regformV4User);
              dispatch.languageList.setLanuageList(mapLang(locales));
              dispatch.locale.updateLocale(mapLang(locales)[0]);
              dispatch.countryCodes.setCountryCodes(countryCodes);
              dispatch.industriesOption.setIndustriesOption(industriesOptions);
              dispatch.salutations.setSalutations(salutations);
              dispatch.regFormName.setRegFormName(formName);
              dispatch.regformUuid.setRegformUuid(accessKey);
              dispatch.themeColor.setThemeColor(themeColor);
              dispatch.backgroundColor.setBackgroundColor(backgroundColor);
              dispatch.footerColorMode.setFooterColorModeByColor(backgroundColor);
              dispatch.defaultTicket.setDefaultTicket(defaultTicket);
              dispatch.accessCodeEnabled.setAccessCodeEnabled(accessCodeEnabled);
              dispatch.qrCodeUrl.setQrCodeUrl({
                global: qrCodeUrl,
                china: chinaHostQrCodeUrl,
              });
              dispatch.includeTicketing.setIncludeTicketing(includeTicketing);
              if (fontFamily) {
                dispatch.fontFamily.setFontFamily(fontFamily);
              }
              dispatch.mainForm.resetForm();
              dispatch.formKind.updateFormKind(kind);
              dispatch.formSettings.setFormSettings({ googleAnalyticsId, googleTagManagerId });
              dispatch.pageElementId.updatePageElementId(nodes[0].id);
              dispatch.mainFormTotalCount.updateMainFormTotalCount('total', nodes[0].elements.totalCount);
              dispatch.currentInQueue.fetchCustomDomainsByUser();
              dispatch.currentInQueue.fetchSelectedCustomDomainByUser();
              dispatch.initForm.setInitForm(false);
              resolve();
            })
            .catch((error) => {
              // window.Sentry.captureException(error);
              dispatch.hasError.setHasError({ error: true, message: 'fetch_event_info_fail' });
              dispatch.showHelpButton.toggleShowHelpButton();
              console.log(error);
              reject();
            });
        }),
      );
    },
  }),
};

export default eventInfo;
