const isTicketLoading = {
  state: true,
  reducers: {
    setIsTicketLoading(_: boolean, payload: boolean) {
      return payload;
    },
  },
};

export default isTicketLoading;
