import { gql } from '@apollo/client';
import regformTicketFieldsFragment from './fragments/regformTicketFieldsFragment';

const fetchEventTicketQuery = gql`
  query fetchEventTicketQuery($eventId: ID!, $locale: LocaleEnum, $after: String, $first: Int) {
    eventNode: node(id: $eventId) {
      ... on Event {
        tickets (after: $after, first: $first) {
          pageInfo {
              endCursor
              hasNextPage
            }
          edges {
            node @locale(lang: $locale){
              ... regformTicketFields
            }
          }
        }
      }
    }
  }
  ${regformTicketFieldsFragment}
`;

export default fetchEventTicketQuery;
