const needRefetch = {
  state: false,
  reducers: {
    setNeedRefetch(state, payload) {
      return payload;
    },
  },
};

export default needRefetch;
