import { gql } from '@apollo/client';

export default gql`
  fragment ChoiceSelectedAtomicConditionElementNode on RegformChoiceSelectedAtomicCondition {
    choiceElement {
      id
      typeName: __typename
    }
  }
`;
