import { gql } from '@apollo/client';
import fragmentLogic from './fragments/fragmentLogic';

const fetchFormLogicQuery = gql`
  query fetchFormLogicQuery($registrationFormId: ID!) {
    node(id: $registrationFormId) {
      ... on RegformV3Form {
        ...LogicFragment
      }
    }
  }
  ${fragmentLogic}
`;

export default fetchFormLogicQuery;
