import { Action, init, RematchStore } from '@rematch/core';
import {
  MakeStore, createWrapper, Context, HYDRATE,
} from 'next-redux-wrapper';
import { useMemo } from 'react';
import { combineReducers } from 'redux';
import * as models from '../registration_form/Model';
import * as builderModels from '../registration_form_builder/Model';

let store;

const exampleInitialState = {};

// TODO: Refactor Builder and Registration Form Store
export const initStore = (initialState = exampleInitialState) => init({
  models,
  redux: {
    initialState,
    combineReducers: (reducers) => {
      const combinedReducer = combineReducers(reducers);
      const hydrateReducer = (state, action) => {
        if (action.type === HYDRATE) {
          const nextState = {
            ...state, // use previous state
            ...action.payload, // apply delta from hydration
          };
          return nextState;
        }
        return combinedReducer(state, action);
      };
      return hydrateReducer;
    },
  },
});

export const initializeStore = (
  preloadedState: Record<string, unknown>,
): RematchStore<typeof models, Action<any, any>> => {
  let initializedStore = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    initializedStore = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return initializedStore;
  // Create the store once in the client
  if (!store) store = initializedStore;

  return initializedStore;
};

export const initBuilderStore = (initialState = exampleInitialState) => init({
  models: builderModels,
  redux: {
    initialState,
    combineReducers: (reducers) => {
      const combinedReducer = combineReducers(reducers);
      const hydrateReducer = (state, action) => {
        if (action.type === HYDRATE) {
          const nextState = {
            ...state, // use previous state
            ...action.payload, // apply delta from hydration
          };
          return nextState;
        }
        return combinedReducer(state, action);
      };
      return hydrateReducer;
    },
  },
});

export const initializeBuilderStore = (
  preloadedState: Record<string, unknown>,
): RematchStore<typeof models, Action<any, any>> => {
  let initializedStore = store ?? initBuilderStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    initializedStore = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return initializedStore;
  // Create the store once in the client
  if (!store) store = initializedStore;

  return initializedStore;
};

export function useStore(initialState) {
  const storeHook = useMemo(() => initializeStore(initialState), [initialState]);
  return storeHook;
}

export const wrapper = createWrapper(initializeStore, { debug: process.env.NODE_ENV !== 'production' });
export const builderWrapper = createWrapper(initializeBuilderStore, { debug: process.env.NODE_ENV !== 'production' });
