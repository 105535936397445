import 'regenerator-runtime/runtime';
import _cloneDeep from 'lodash/cloneDeep';
import _keyBy from 'lodash/keyBy';
import _countBy from 'lodash/countBy';
import { sendGa } from 'helpers/ga';
import getValueTypeFromTypeName from 'helpers/getValueTypeFromTypeName';
import FormValueType from 'schema/formValueType';
import RegformFieldElement from 'schema/fieldElement';
import isMcQuestion from 'helpers/isMcQuestion';
import AttendeeUpdateQueue from 'helpers/attendeeUpdateQueue';
import scrollToErrorElement from 'helpers/scrollToErrorElement';
import {
  orderAddAttendeeBatchMutation,
  orderRemoveAttendeeMutation, fetchAttendeeQuery,
} from './Graphql';

const validateAnswer = (Component) => {
  let newComponent = _cloneDeep(Component);
  let errorMessage;
  if (Component.required && Component.shouldRender) {
    if (!Component.questionAnswer
      || Component.questionAnswer === ''
      || Component.questionAnswer === false
      || ((Array.isArray(Component.questionAnswer) && Component.questionAnswer.length === 0)
      || ((typeof (Component.questionAnswer) === 'object'
        && Object.keys(Component.questionAnswer).length < 1))
      )
    ) {
      errorMessage = 'regsvp.error.please_answer_this_question';
    } else if (![
      RegformFieldElement.PrimaryEmail,
      RegformFieldElement.SecondaryEmail,
      RegformFieldElement.Phone,
    ].includes(Component.typeName)) {
      errorMessage = '';
    }
  }
  if (typeof errorMessage !== 'undefined') {
    newComponent = {
      ...newComponent,
      errorMessage,
    };
  }
  return newComponent;
};

const combineMainForm = ({ sourceForm, targetForm }) => sourceForm.map((formData, index) => {
  const { typeName } = formData;
  const previousValue = sourceForm[index].questionAnswer;
  if (typeName === RegformFieldElement.FileUploadQuestion || !previousValue) {
    return validateAnswer(_cloneDeep(targetForm[index]));
  }
  const clonedFormData = validateAnswer(_cloneDeep(formData));
  if (typeName === RegformFieldElement.Phone) {
    clonedFormData.questionAnswer = clonedFormData.questionAnswer.replace(/\s+/g, '');
  }
  return clonedFormData;
});

const attendee = {
  state: [],
  reducers: {
    setAttendee(state, payload) {
      return payload;
    },
    addAttendeeObject(state, payload) {
      state.push(payload);
      return state;
    },
    bulkUpdateAttendee(state, payload) {
      return payload;
    },
    updateComponentAttr(state, {
      attrName,
      targetObjectId,
      attendeeId,
      value,
      localValue,
    }) {
      let updateValue;
      if (localValue) {
        updateValue = localValue;
      } else {
        updateValue = value;
      }
      const newState = state.map((eachAttendee) => {
        if (eachAttendee.attendeeId === attendeeId) {
          const newMainForm = eachAttendee.mainForm.map((eachComponent) => {
            if (eachComponent.id === targetObjectId) {
              eachComponent = {
                ...eachComponent,
                [attrName]: updateValue,
                dirty: true,
              };
              return validateAnswer(eachComponent);
            }
            return eachComponent;
          });
          return { ...eachAttendee, mainForm: newMainForm };
        }
        return eachAttendee;
      });
      return newState;
    },
    updateAttendeeAttr(state, {
      attrName,
      attendeeId,
      value,
    }) {
      const newState = state.map((eachAttendee) => {
        if (eachAttendee.attendeeId === attendeeId) {
          let newAttendee = _cloneDeep(eachAttendee);
          newAttendee = {
            ...newAttendee,
            [attrName]: value,
          };
          return newAttendee;
        }
        return eachAttendee;
      });
      return newState;
    },
  },
  effects: (dispatch) => ({
    async addTicketToAttendee(_, state) {
      sendGa('Continue with Selected Ticket');
      const ticketList = [];
      dispatch.alreadyWarned.setAlreadyWarned(false);
      if (state.includeTicketing) {
        state.ticket.forEach((eachTicket) => {
          if (eachTicket.selectedQuatity !== 0) {
            for (let i = eachTicket.selectedQuatity; i > 0; i -= 1) {
              ticketList.push(eachTicket.id);
            }
          }
        });
      } else {
        ticketList.push(state.defaultTicket.ticketId);
      }
      if (ticketList.length > 0) {
        const list = await dispatch.currentInQueue.addAttendeeMutation(ticketList.length)
          .then(async (attendeeIds) => {
            await dispatch.currentInQueue.addTicketMutation({ attendeeIds, ticketList });
          }).catch((error) => {
            console.error(error);
          });
        dispatch.errorText.setErrorText([]);
        await Promise.all([list]).then(() => {
          if (state.discountCodeEnabled && state.appliedDiscountCode.length > 0) {
            dispatch.currentInQueue.applyDiscountCode();
          } else {
            dispatch.currentInQueue.orderLockQuotaMutation();
          }
        });
      } else {
        dispatch.isSubmiting.setIsSubmiting(false);
        dispatch.errorText.setErrorText([{ type: 'regform_v3.error_messages.no_ticket_selected' }]);
      }
    },
    addAttendee({ queue, client, noOfTicket }, state) {
      const maxCountPerRequest = 50;
      const tasks = [];
      let remaining = noOfTicket;
      while (remaining > 0) {
        tasks.push(remaining - maxCountPerRequest >= 0 ? maxCountPerRequest : remaining);
        remaining -= maxCountPerRequest;
      }

      return queue.add(async () => {
        try {
          const result = [];
          for (let i = 0; i < tasks.length; i += 1) {
            // eslint-disable-next-line no-await-in-loop
            const { data } = await client
              .mutate({ mutation: orderAddAttendeeBatchMutation(state.orderAccessKey, tasks[i]) });
            result.push(...data.orderAddAttendeeBatch.attendees.map((it) => it.attendeeId));
          }
          return result;
        } catch (error) {
          dispatch.formError.setFormError({ value: true, reason: 'form_updated' });
          console.error(error);
          return Promise.reject(error);
        }
      });
    },
    copyAttendee: async ({
      sourceAttendeeId,
      targetAttendeeId,
    }, state) => {
      dispatch.isSubmiting.setIsSubmiting(true);
      try {
        if (!sourceAttendeeId) {
          return;
        }
        const { attendee: currentAttendees } = state;
        const sourceAttendeeData = currentAttendees.find(
          (eachAttendee) => eachAttendee.attendeeId === sourceAttendeeId,
        );
        const targetAttendeeIndex = currentAttendees.findIndex(
          (eachAttendee) => eachAttendee.attendeeId === targetAttendeeId,
        );
        const targetAttendeeData = currentAttendees[targetAttendeeIndex];
        const updatedAttendee = _cloneDeep(currentAttendees);
        const updatedData = {
          ...targetAttendeeData,
          mainForm: combineMainForm({
            sourceForm: sourceAttendeeData.mainForm,
            targetForm: targetAttendeeData.mainForm,
          }),
        };
        updatedAttendee[targetAttendeeIndex] = updatedData;
        dispatch.attendee.bulkUpdateAttendee(updatedAttendee);
        const { mainForm: updatedFormData } = updatedData;
        const formUpdates = updatedFormData
          .filter(({ typeName }) => {
            const valueType = getValueTypeFromTypeName(typeName);
            return valueType && valueType !== FormValueType.FILE;
          })
          .filter(({ questionAnswer }) => !!questionAnswer)
          .map(({ id, typeName, questionAnswer }) => {
            const valueType = getValueTypeFromTypeName(typeName);
            const isEmptyText = valueType === FormValueType.STRING && !questionAnswer;
            return {
              fieldElementId: id,
              valueType,
              attendeeId: targetAttendeeId,
              value: isEmptyText ? '' : questionAnswer,
            };
          });
        AttendeeUpdateQueue.addBatch(formUpdates);
      } catch (e) {
        console.error(e);
      } finally {
        dispatch.isSubmiting.setIsSubmiting(false);
      }
    },
    removeAllAttendeeFromOrder({ queue, client }, state) {
      dispatch.isSubmiting.setIsSubmiting(true);
      queue.add(
        () => new Promise((resolve, reject) => {
          const attendeeIds = state.attendee.map((eachAttendee) => (eachAttendee.attendeeId));
          client
            .mutate({
              mutation: orderRemoveAttendeeMutation(
                state.orderAccessKey,
                attendeeIds,
                state.appliedDiscountCode,
              ),
            })
            .then(({ data }) => {
              Object.keys(data).forEach(
                (eachAttendeeKey) => {
                  if (!data[eachAttendeeKey].success) {
                    reject();
                  }
                },
              );
              dispatch.ticket.unselectTicket();
              dispatch.discountCodes.resetDiscountCode();
              dispatch.appliedDiscountCode.resetDiscountCode();
              dispatch.paymentPage.resetPaymentPage();
              dispatch.attendee.bulkUpdateAttendee([]);
              dispatch.isSubmiting.setIsSubmiting(false);
              resolve();
            }).catch((error) => {
              dispatch.formError.setFormError({ value: true, reason: 'remove_attendee_error' });
              console.error(error);
              reject(error);
            });
        }),
      );
    },
    async validateAndSubmitForm(_, state) {
      let readyForSubmit = true;
      let scrollTo = '';
      const attendees = state.attendee.map((eachAttendee) => {
        const newMainForm = eachAttendee.mainForm.map((eachComponent) => {
          const newComponent = validateAnswer(eachComponent);
          const { attendeeId } = eachAttendee;
          const { id: fieldElementId, errorMessage } = newComponent;
          if (errorMessage && scrollTo === '') {
            readyForSubmit = false;
            scrollTo = fieldElementId + attendeeId;
            eachAttendee = {
              ...eachAttendee,
              hasError: scrollTo,
            };
            scrollToErrorElement({ fieldElementId, attendeeId });
          }
          return newComponent;
        });
        return {
          ...eachAttendee,
          mainForm: newMainForm,
        };
      });
      if (readyForSubmit) {
        dispatch.isSubmiting.setIsSubmiting(true);
        await dispatch.form.forceComponentAttrMutation();
        sendGa('Submitted Form');
        dispatch.currentInQueue.processToPayment();
      } else {
        dispatch.attendee.bulkUpdateAttendee(attendees);
      }
    },
    resetLogicalQuestionValues({ attendeeId, targetObjectId }, state) {
      const pageNodes = state.form[0];
      const targetElement = pageNodes.find(({ id }) => id === targetObjectId);
      const { isLogicalAffector, hasAffectorChildren } = targetElement;
      if (isLogicalAffector || hasAffectorChildren) {
        const targetAttendee = state.attendee.find(({ attendeeId: id }) => id === attendeeId);
        const newMainForm = targetAttendee.mainForm.map((eachComponent) => {
          const { logics: { nodes: logicNodes }, dirty } = eachComponent;
          if (logicNodes.length > 0) {
            const { condition } = logicNodes[0];
            if (condition.typeName === 'RegformAnyCompoundCondition') {
              const { subConditions: { nodes: subCondtionsNodes } } = condition;
              if (subCondtionsNodes.length > 0) {
                const isAffectedQuestion = subCondtionsNodes.some((subCondition) => {
                  if (subCondition.typeName === 'RegformChoiceSelectedAtomicCondition') {
                    const {
                      choiceElement: {
                        mcQuestion: {
                          id: affectingQuestionId,
                        },
                      },
                    } = subCondition;
                    const { mainForm: formQuestions } = targetAttendee;
                    return formQuestions.some(
                      ({ id: questionId }) => questionId === affectingQuestionId,
                    );
                  }
                  return false;
                });
                if (isAffectedQuestion) {
                  const showQuestion = subCondtionsNodes.some((subCondition) => {
                    if (subCondition.typeName === 'RegformChoiceSelectedAtomicCondition') {
                      const {
                        choiceElement: {
                          choice: {
                            id: affectingChoiceId,
                          },
                          mcQuestion: {
                            id: affectingQuestionId,
                          },
                        },
                      } = subCondition;
                      const { mainForm: formQuestions } = targetAttendee;
                      const affectingQuestion = formQuestions.find(
                        ({ id: questionId }) => questionId === affectingQuestionId,
                      );
                      const { questionAnswer: selectedChoices } = affectingQuestion;
                      if (selectedChoices.some((choiceId) => choiceId === affectingChoiceId)) {
                        return true;
                      }
                      return false;
                    }
                    return false;
                  });
                  if (!showQuestion && eachComponent.shouldRender) {
                    let answer;
                    let valueType;
                    if (isMcQuestion(eachComponent.typeName)) {
                      answer = [];
                      valueType = FormValueType.COLLECTION;
                      dispatch.attendee.updateComponentAttr({
                        attrName: 'questionAnswer',
                        targetObjectId: eachComponent.id,
                        attendeeId,
                        valueType,
                        value: answer,
                      });
                      dispatch.attendee.resetLogicalQuestionValues({
                        attendeeId, targetObjectId: eachComponent.id,
                      });
                    } else {
                      answer = '';
                      valueType = FormValueType.STRING;
                    }
                    eachComponent = {
                      ...eachComponent,
                      questionAnswer: answer,
                      dirty: false,
                      errorMessage: '',
                    };
                    if (dirty) {
                      dispatch.currentInQueue.updateComponentAttrMutation({
                        attrName: 'questionAnswer',
                        targetObjectId: eachComponent.id,
                        attendeeId,
                        value: answer,
                        valueType,
                      });
                    }
                  }
                  eachComponent = {
                    ...eachComponent,
                    shouldRender: showQuestion,
                  };
                }
              }
            }
          }
          return eachComponent;
        });
        dispatch.attendee.updateAttendeeAttr({
          attendeeId,
          attrName: 'mainForm',
          value: newMainForm,
        });
      }
    },
    fetchAttendeeByOrder({ client, queue }, state) {
      return queue.add(
        async () => {
          try {
            const languageList = state.languageList.map(({ code }) => code);
            const result = await client
              .query({
                variables: {
                  accessKey: state.orderAccessKey,
                  en: languageList.includes('en'),
                  zh_hk: languageList.includes('zh_hk'),
                  zh_cn: languageList.includes('zh_cn'),
                  ja: languageList.includes('ja'),
                  ko: languageList.includes('ko'),
                  ru: languageList.includes('ru'),
                },
                query: fetchAttendeeQuery,
                fetchPolicy: 'no-cache',
              });
            const { data: { orderByAccessKey: { attendees: { nodes: attendees } } } } = result;
            await Promise.all(
              attendees.map((each) => dispatch.attendee.parseAttendeeObject(each)),
            );
            const allAttendeeTickets = attendees
              .flatMap((eachAttendee) => eachAttendee.attendeeTickets[state.locale.code]);
            const ticketQuantityById = _countBy(
              allAttendeeTickets,
              ({ ticket: { id: ticketId } }) => ticketId,
            );
            state.ticket.forEach(({ id: ticketId }, index) => {
              if (ticketId in ticketQuantityById) {
                const selectedQuatity = ticketQuantityById[ticketId];
                dispatch.ticket.updateTicket({ index, selectedQuatity });
              }
            });
          } catch (e) {
            console.error(e);
            throw e;
          }
        },
      );
    },
    parseAttendeeObject(attendeeObject, state) {
      return new Promise((resolve) => {
        const {
          attendeeId: id,
          attendeeTickets,
          firstName,
          lastName,
          email,
          phone,
          salutation,
          company,
          jobPosition,
          industry,
          country,
          city,
          mainForm,
        } = attendeeObject;
        const mainFormAnswers = mainForm.pages.nodes[0].elements.nodes;
        const mainFormAnswersById = _keyBy(mainFormAnswers, 'id');
        const { elements: { [state.locale.code]: elementsNodes } } = state.formData[0];
        const newElements = elementsNodes.map((eachField, index) => {
          const updatedFieldData = { ...eachField };
          updatedFieldData.shouldRender = true;
          const { logics: { nodes: logicNodes } } = updatedFieldData;
          if (logicNodes.length > 0) {
            const { condition } = logicNodes[0];
            if (condition.typeName === 'RegformAnyCompoundCondition') {
              const { subConditions: { nodes: subCondtionsNodes } } = condition;
              if (subCondtionsNodes.length > 0) {
                const shouldRender = subCondtionsNodes.some((subCondition) => {
                  const { choiceElement: { choice: { id: choiceId }, mcQuestion: { id: questionId } } } = subCondition;
                  return mainFormAnswersById[questionId]?.value?.chosenChoices?.nodes?.some((node) => node.choiceId === choiceId);
                });
                updatedFieldData.shouldRender = shouldRender;
              }
            }
          }
          switch (updatedFieldData.typeName) {
            case RegformFieldElement.CheckboxMcQuestion:
            case RegformFieldElement.RadioMcQuestion:
            case RegformFieldElement.DropdownMcQuestion:
              updatedFieldData.questionAnswer = mainFormAnswersById[updatedFieldData.id]?.value
                ?.chosenChoices?.nodes?.map(({ choiceId }) => choiceId) ?? [];
              break;
            case RegformFieldElement.WaiverQuestion:
            case RegformFieldElement.CheckboxQuestion:
              updatedFieldData.questionAnswer = mainFormAnswersById[updatedFieldData.id]
                .value?.value || false;
              break;
            case RegformFieldElement.StringQuestion:
            case RegformFieldElement.SecondaryEmail:
            case RegformFieldElement.TextQuestion:
              updatedFieldData.questionAnswer = mainFormAnswersById[updatedFieldData.id].value?.value?.[0] || '';
              break;
            case RegformFieldElement.FileUploadQuestion: {
              const hasFile = mainFormAnswersById[updatedFieldData.id].value?.files?.[0]
              && mainFormAnswersById[updatedFieldData.id].value?.files?.[0].contentType;
              updatedFieldData.questionAnswer = hasFile
                ? mainFormAnswersById[updatedFieldData.id].value?.files?.[0] : {};
              break;
            }
            case RegformFieldElement.FirstName:
              updatedFieldData.questionAnswer = firstName;
              break;
            case RegformFieldElement.LastName:
              updatedFieldData.questionAnswer = lastName;
              break;
            case RegformFieldElement.Salutation:
              updatedFieldData.questionAnswer = salutation;
              break;
            case RegformFieldElement.PrimaryEmail:
              updatedFieldData.questionAnswer = email;
              break;
            case RegformFieldElement.Phone:
              updatedFieldData.questionAnswer = phone;
              break;
            case RegformFieldElement.Country:
              updatedFieldData.questionAnswer = country;
              break;
            case RegformFieldElement.City:
              updatedFieldData.questionAnswer = city;
              break;
            case RegformFieldElement.Company:
              updatedFieldData.questionAnswer = company;
              break;
            case RegformFieldElement.Title:
              updatedFieldData.questionAnswer = jobPosition;
              break;
            case RegformFieldElement.Industry:
              updatedFieldData.questionAnswer = industry;
              break;
            default:
              updatedFieldData.questionAnswer = '';
              break;
          }
          updatedFieldData.errorMessage = '';
          updatedFieldData.required = state.form[0][index].required;
          return updatedFieldData;
        });
        const newAttendee = {
          attendeeId: id,
          attendeeTickets,
          mainForm: newElements,
          hasError: '',
        };
        dispatch.attendee.addAttendeeObject(newAttendee);
        resolve();
      });
    },
  }),
};
export default attendee;
