const actualPrice = {
  state: '0.00',
  reducers: {
    setActualPrice(state, payload) {
      return payload;
    },
  },
};

export default actualPrice;
