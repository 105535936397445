const fontFamily = {
  state: 'Lato',
  reducers: {
    setFontFamily(state, payload) {
      return payload;
    },
  },
};

export default fontFamily;
