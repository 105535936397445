const regFormId = {
  state: '',
  reducers: {
    setRegFormId(state, payload) {
      return payload;
    },
  },
};

export default regFormId;
