import validateAccessCodeMutation from './Graphql/validateAccessCodeMutation';

const accessCode = {
  state: {
    code: '',
    isEnabled: true,
    errorMessage: '',
  },
  reducers: {
    setAccessCode(state, code) {
      return {
        ...state,
        code,
      };
    },
    setAccessCodeEnabled(state, isEnabled) {
      return {
        ...state,
        isEnabled,
      };
    },
    setAccessCodeErrorMessage(state, errorMessage) {
      return {
        ...state,
        errorMessage,
      };
    },
  },
  effects: (dispatch) => ({
    validateAccessCode({ queue, client }, state) {
      return queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                orderAccessKey: state.orderAccessKey,
                accessCode: state.accessCode.code,
              },
              mutation: validateAccessCodeMutation,
            })
            .then(({ data }) => {
              const {
                orderApplyAccessCode: {
                  success,
                  errors,
                },
              } = data;
              if (errors.length) {
                dispatch.accessCode.setAccessCodeErrorMessage(`regform_v3.error_messages.${errors[0].type}`);
              } else {
                dispatch.accessCode.setAccessCodeErrorMessage('');
              }
              resolve(success);
            })
            .catch((error) => {
              console.error(error);
              reject(error);
            });
        }),
      );
    },
    saveAccessCodeToLocal(payload, state) {
      window.localStorage.setItem('accessCode', state.accessCode.code);
    },
  }),
};

export default accessCode;
