const featureGuard = {
  state: {
    regformV4Payment: 'on',
    formCountdown: 'on',
    chinaFriendlyModeEnabled: 'off',
  },
  reducers: {
    setFeatureGuardByKey(state, payload) {
      const { key, treatment } = payload;
      const currentFeatureGuard = { ...state };
      currentFeatureGuard[key] = treatment;
      return currentFeatureGuard;
    },
  },
};
export default featureGuard;
