import { SplitFactory } from '@splitsoftware/splitio';

const splitClient = {
  state: null,
  reducers: {
    setSplitClient(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    initSplitClient({ regformUuid }, state) {
      const factory = SplitFactory({
        core: {
          authorizationKey: process.env.NEXT_PUBLIC_SPLIT_IO_API_KEY,
          key: regformUuid,
        },
        debug: true,
      });
      const splitIoClient = factory.client();
      dispatch.splitClient.setSplitClient(splitIoClient);
      splitIoClient.on(splitIoClient.Event.SDK_READY, () => {
        const chinaFriendlyModeEnabled = splitIoClient.getTreatment('regform_china_friendly_mode_enabled');
        dispatch.featureGuard.setFeatureGuardByKey({
          key: 'chinaFriendlyModeEnabled',
          treatment: chinaFriendlyModeEnabled === 'on',
        });
      });
    },
  }),
};

export default splitClient;
