const ticketError = {
  state: { value: false, reason: '' },
  reducers: {
    setTicketError(state, payload) {
      return payload;
    },
  },
};

export default ticketError;
