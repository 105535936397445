const customDomainEnabled = {
  state: false,
  reducers: {
    setCustomDomainEnabled(state, payload) {
      return payload;
    },
  },
};

export default customDomainEnabled;
