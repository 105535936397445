const component = {
  state: {},
  reducers: {
    initComponent(state, fromComponent) {
      return fromComponent;
    },
    disableComponent(state, componentId) {
      if (state[componentId].isMultiple) {
        return state;
      }
      const newstate = {
        ...state,
        [componentId]: { ...state[componentId], isDisabled: true },
      };
      return newstate;
    },
    resetIsDisable(state, componentId) {
      const newState = {
        ...state,
        [componentId]: {
          ...state[componentId],
          isDisabled: false,
        },
      };
      return newState;
    },
  },
};
export default component;
