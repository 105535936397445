import { updateRegFormMutation } from '../Graphql';

const regFormName = {
  state: '',
  reducers: {
    setRegFormName(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    updateRegFormName({ payload, queue, client }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                regFormElementId: state.regFormElementId,
                attributes: [{ name: 'formName', value: { stringValue: payload } }],
              },
              mutation: updateRegFormMutation,
            })
            .then((data) => {
              const {
                data: {
                  regformBuilderObjectSetAttributes: { success },
                },
              } = data;
              dispatch.networkError.setNetworkError(!success);
              if (success) {
                dispatch.regFormName.setRegFormName(payload);
              }
              resolve();
            })
            .catch((error) => {
              // window.Sentry.captureException(error);
              reject();
            })
            .finally(() => {
              dispatch.currentInQueue.updateCurrentInQueue(
                queue.size + queue.pending,
              );
            });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
    },
  }),
};
export default regFormName;
