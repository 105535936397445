import Immutable from 'immutable';
import { addTicketMutation, reorderTicketMutation, removeTicketMutation } from '../Graphql';

const selectedTicket = {
  state: Immutable.List(),
  reducers: {
    setSelectedTicket(state, payload) {
      return Immutable.List(payload);
    },
    addSelectTicket(state, payload) {
      const newState = state.push(...payload);
      return newState;
    },
    rearrangeTicket(state, sourceIndex, destinationIndex) {
      const targetTicket = state.get(sourceIndex);
      const newState = state.delete(sourceIndex).insert(destinationIndex, targetTicket);
      return newState;
    },
    addTicketToForm(state, { newTicket, position }) {
      return state.insert(position, newTicket);
    },
    removeTicket(state, index) {
      return state.delete(index);
    },
  },
  effects: (dispatch) => ({
    insertTicket({
      newTicket, position, queue, client,
    }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                regformId: state.regFormElementId,
                ticketElementID: state.ticketElementID,
                position,
                ticketId: newTicket.ticket.id,
              },
              mutation: addTicketMutation,
            })
            .then((data) => {
              const {
                data: {
                  regformBuilderCollectionAddObject: {
                    success,
                    newObject: {
                      id,
                    },
                  },
                },
              } = data;
              dispatch.networkError.setNetworkError(!success);
              if (success) {
                const newState = state.selectedTicket.map((eachSelectedTicket) => {
                  if (eachSelectedTicket.id === newTicket.id) {
                    eachSelectedTicket.id = id;
                  }
                  return eachSelectedTicket;
                });
                dispatch.selectedTicket.setSelectedTicket(newState);
              }
              resolve();
            })
            .catch((error) => {
              dispatch.networkError.setNetworkError(true);
              dispatch.selectedTicket.removeTicket(position);
              // window.Sentry.captureException(error);
              reject();
            })
            .finally(() => {
              dispatch.currentInQueue.updateCurrentInQueue(
                queue.size + queue.pending,
              );
            });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
    },
    reorderTicket({
      ticketId, source, destination, queue, client,
    }, state) {
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                regFormElementId: state.regFormElementId,
                ticketElementID: state.ticketElementID,
                position: destination,
                ticketId,
              },
              mutation: reorderTicketMutation,
            })
            .then((data) => {
              const {
                data: {
                  regformBuilderCollectionReorderObject: { success },
                },
              } = data;
              dispatch.networkError.setNetworkError(!success);
              resolve();
            })
            .catch((error) => {
              dispatch.networkError.setNetworkError(true);
              dispatch.selectedTicket.rearrangeTicket(
                destination,
                source,
              );
              // window.Sentry.captureException(error);
              console.log(error);
              reject();
            })
            .finally(() => {
              dispatch.currentInQueue.updateCurrentInQueue(
                queue.size + queue.pending,
              );
            });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
    },
    deleteTicket({
      ticketInfo, index, queue, client,
    }, state) {
      dispatch.selectedTicket.removeTicket(index);
      dispatch.eventTicket.resetDisabledState(ticketInfo.ticket.id);
      queue.add(
        () => new Promise((resolve, reject) => {
          client
            .mutate({
              variables: {
                regformId: state.regFormElementId,
                ticketElementID: state.ticketElementID,
                ticketId: ticketInfo.id,
              },
              mutation: removeTicketMutation,
            })
            .then((data) => {
              const {
                data: {
                  regformBuilderCollectionRemoveObject: { success },
                },
              } = data;
              dispatch.networkError.setNetworkError(!success);
              resolve(data);
            })
            .catch((error) => {
              dispatch.networkError.setNetworkError(true);
              dispatch.eventTicket.setTicketDisable(ticketInfo.ticket.id, true);
              dispatch.selectedTicket.addTicketToForm({ newTicket: ticketInfo, position: index });
              // window.Sentry.captureException(error);
              reject();
            })
            .finally(() => {
              dispatch.currentInQueue.updateCurrentInQueue(
                queue.size + queue.pending,
              );
            });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
    },
  }),
};

export default selectedTicket;
