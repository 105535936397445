import axios from 'axios';
import _debounce from 'lodash/debounce';

const environment = process.env.NODE_ENV === 'production' ? 'PRO' : 'STG';

const temp = _debounce(
  (queue, userUuid, regformUuid, domainId, path, setNetworkError, updateCurrentInQueue) => {
    console.log('called');
    queue.add(
      () => new Promise((resolve, reject) => {
        axios.post(`/api/v1/users/${userUuid}/formsByUUID/${regformUuid}/domains`, { domainId, path, environment })
        .then(({ data: { success, data } })  => {
          setNetworkError(!success);
          resolve(success);
        })
        .catch((error) => {
          console.log(error);
          setNetworkError(true);
          resolve(false);
        })
        .finally(() => {
          updateCurrentInQueue(
            queue.size + queue.pending,
          );
        });
      }),
    );
    updateCurrentInQueue(
      queue.size + queue.pending,
    );
  },
  1500
);

const customDomains = {
  state: {
    selectedCustomDomain: null,
    customDomainsList: [],
  },
  reducers: {
    setCustomDomains(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    setSelectedCustomDomain(state, payload) {
      return {
        ...state,
        selectedCustomDomain: {
          ...state.selectedCustomDomain,
          ...payload,
        }
      }
    },
    resetSelectedCustomDomain(state, payload) {
      return {
        ...state,
        selectedCustomDomain: null,
      }
    },
  },
  effects: (dispatch) => ({
    fetchCustomDomainsByUser({ queue }, state) {
      const { userUuid } = state;
      queue.add(
        () => new Promise((resolve, reject) => {
          axios.get(`/api/v1/users/${userUuid}/domains`)
          .then(({ data: { data } })  => {
            const processedData = data.map((customDomain) => ({
              ...customDomain,
              label: customDomain.domain,
              value: customDomain.id,
            }))
            dispatch.customDomains.setCustomDomains({ customDomainsList: processedData });
            dispatch.networkError.setNetworkError(false);
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            dispatch.networkError.setNetworkError(true);
            resolve(true);
          })
        }),
      );
    },
    fetchSelectedCustomDomainByUser({ queue }, state) {
      const { userUuid, regformUuid } = state;
      queue.add(
        () => new Promise((resolve, reject) => {
          axios.get(`/api/v1/users/${userUuid}/formsByUUID/${regformUuid}/domains`)
          .then(({ data: { success, data } })  => {
            if (data) {
              const selectedCustomDomain = {
                ...data,
                label: data.domain,
                value: data.domainId,
              }
              dispatch.customDomains.setSelectedCustomDomain(selectedCustomDomain);
            }
            dispatch.networkError.setNetworkError(!success);
            resolve(success);
          })
          .catch((error) => {
            console.log(error);
            dispatch.networkError.setNetworkError(true);
            resolve(false);
          })
        }),
      );
    },
    createOrUpdateCustomDomain({ queue, customDomain }, state) {
        const { userUuid, regformUuid } = state;
        const { value: domainId = "", path = "" } = customDomain;
        temp(
          queue,
          userUuid,
          regformUuid,
          domainId,
          path,
          dispatch.networkError.setNetworkError,
          dispatch.currentInQueue.updateCurrentInQueue,
        );
    },
    deleteCustomDomain({ queue }, state) {
      const { userUuid, regformUuid } = state;
      queue.add(
        () => new Promise((resolve, reject) => {
          axios.delete(`/api/v1/users/${userUuid}/formsByUUID/${regformUuid}/domains`)
          .then(({ data: { success } })  => {
            dispatch.networkError.setNetworkError(!success);
            resolve(success);
          })
          .catch((error) => {
            console.log(error);
            dispatch.networkError.setNetworkError(true);
            resolve();
          })
          .finally(() => {
            dispatch.currentInQueue.updateCurrentInQueue(
              queue.size + queue.pending,
            );
          });
        }),
      );
      dispatch.currentInQueue.updateCurrentInQueue(
        queue.size + queue.pending,
      );
    }
  })
}

export default customDomains;
