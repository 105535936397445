import _Some from 'lodash/some';

const discountCodes = {
  state: [],
  reducers: {
    setDiscountCode(state, payload) {
      if (!_Some(state, payload)) {
        switch (payload.discountOffersAvailable.nodes[0].discountType) {
          case 'OrderPercentageDiscountOffer': {
            payload = {
              ...payload,
              discountType: 'percentage',
            };
            break;
          }
          case 'OrderFixedAmountDiscountOffer': {
            payload = {
              ...payload,
              discountType: 'fixed_amount',
            };
            break;
          }
          case 'OrderBuyXGetYFreeDiscountOffer': {
            payload = {
              ...payload,
              discountType: 'quantity',
            };
            break;
          }
          default:
            return null;
        }
        state.push(payload);
        return state;
      }
      return state;
    },
    removeDiscountCode() {
      return [];
    },
  },
  effects: (dispatch) => ({
    resetDiscountCode() {
      dispatch.discountCodes.removeDiscountCode();
      dispatch.discountCodeError.setDiscountCodeError('');
    },
  }),
};

export default discountCodes;
