const isUploadingFile = {
  state: 0,
  reducers: {
    setIsUploadingFile(_, payload) {
      return payload;
    },
    increaseCurrentUploading(state) {
      return state + 1;
    },
    reduceCurrentUploading(state) {
      return state - 1;
    },
  },
};

export default isUploadingFile;
