import { gql } from '@apollo/client';

const fetchThankYouPageQuery = gql`
  query getThankYouPage($regformId: ID!) {
    node(id: $regformId) {
      ... on RegformV3Form {
        status
        thankYouPageViewElement {
          pages {
            nodes {
              id
              elements {
                en: nodes @locale(lang: en) {
                  ...ElementNode
                }
                zh_hk: nodes @locale(lang: zh_hk) {
                  ...ElementNode
                }
                zh_cn: nodes @locale(lang: zh_cn) {
                  ...ElementNode
                }
                ja: nodes @locale(lang: ja) {
                  ...ElementNode
                }
                ko: nodes @locale(lang: ko) {
                  ...ElementNode
                }
                ru: nodes @locale(lang: ru) {
                  ...ElementNode
                }
              }
            }
          }
        }
        ...rsvpNoThankYouPage
      }
    }
  }

  fragment rsvpNoThankYouPage on RegformRsvpForm {
    rsvpNoThankYouPageViewElement {
      pages {
        nodes {
          id
          elements {
            en: nodes @locale(lang: en) {
              ...ElementNode
            }
            zh_hk: nodes @locale(lang: zh_hk) {
              ...ElementNode
            }
            zh_cn: nodes @locale(lang: zh_cn) {
              ...ElementNode
            }
            ja: nodes @locale(lang: ja) {
              ...ElementNode
            }
            ko: nodes @locale(lang: ko) {
              ...ElementNode
            }
            ru: nodes @locale(lang: ru) {
              ...ElementNode
            }
          }
        }
      }
    }
  }

  fragment ElementNode on RegformBuilderObject {
    id
    typeName: __typename
    builderAttributes {
      ...BuilderAttribute
    }
  }

  fragment BuilderAttribute on RegformBuilderAttribute {
    localizable
    name
    value {
      booleanValue
      decimalValue
      idValue
      intValue
      stringValue
      fileValue {
        contentType
        fingerprint
        size
        url
      }
    }
    valueType
  }
`;

export default fetchThankYouPageQuery;
