import Immutable, { List } from 'immutable';
import _cloneDeep from 'lodash/cloneDeep';

const logicalConditions = {
  state: Immutable.fromJS({}),
  reducers: {
    setInLogicalConditions(state, path, value) {
      let newState = _cloneDeep(state);
      newState = newState.setIn(path, value);
      return newState;
    },
    removeConditionByComponent(state, targetComponent) {
      let newState = state;
      if (targetComponent.availableChoices) {
        const { choices } = targetComponent.availableChoices;
        choices.forEach(({ value }) => {
          newState = newState.delete(value);
        });
      }
      newState.entrySeq().forEach(([key, value]) => {
        const optionIndex = value.findIndex(
          ({ targetElementId }) => targetElementId === targetComponent.id,
        );
        if (optionIndex > -1) {
          const conditionList = value.delete(optionIndex);
          newState = newState.setIn([key], conditionList);
        }
      });
      return newState;
    },
    removeConditionByOption(state, optionId) {
      let newState = _cloneDeep(state);
      newState = newState.delete(optionId);
      return newState;
    },
  },
  effects: (dispatch) => ({
    initializeByLogics(payload, state) {
      const { mainForm } = state;
      let conditionObjects = Immutable.fromJS({});
      const afftectLogics = [];
      payload.forEach((logic) => {
        const {
          id: logicId,
          condition,
          targetElement: { id: targetElementId },
        } = logic;
        dispatch.mainForm.updateComponentAttr({
          targetObjectId: targetElementId,
          attrName: 'logicId',
          value: logicId,
        });
        if (condition) {
          const { id: compoundConditionId = '', subConditions: { nodes: subConditionNodes } } = condition;
          dispatch.mainForm.updateComponentAttr({
            targetObjectId: targetElementId,
            attrName: 'compoundConditionId',
            value: compoundConditionId,
          });
          const relatedOptions = [];
          subConditionNodes.forEach((eachSubCondition) => {
            const { id: conditionId, choiceElement: { id: choiceId } } = eachSubCondition;
            relatedOptions.push(choiceId);
            let conditions = conditionObjects.get(choiceId) || List();
            const conditionElement = { conditionId, targetElementId };
            conditions = conditions.push(conditionElement);
            conditionObjects = conditionObjects.set(choiceId, conditions);
          });
          const targetElement = mainForm.find((each) => each.id === targetElementId);
          if (
            targetElement.relatedOptions
            && targetElement.relatedOptions.length > relatedOptions.length
          ) {
            afftectLogics.push(targetElement.labelText || targetElement.labelTextPlaceholder);
          }
          dispatch.mainForm.updateComponentAttr({
            targetObjectId: targetElementId,
            attrName: 'relatedOptions',
            value: relatedOptions,
          });
        }
      });
      dispatch.logicsAffectedByReorder.setAffectedQuestions(afftectLogics);
      dispatch.logicalConditions.setInLogicalConditions([], conditionObjects);
      dispatch.logicalConditions.updateConditionLabelText();
    },
    updateConditionLabelText(payload, state) {
      let newState = state.logicalConditions;
      const { mainForm } = state;
      state.logicalConditions.entrySeq().forEach(([key, value]) => {
        value.entrySeq().forEach(([optionIndex, { targetElementId }]) => {
          const element = mainForm.find(({ id }) => id === targetElementId);
          newState = newState.setIn([key, optionIndex, 'labelText'], element.labelText || element.labelTextPlaceholder);
        });
      });
      dispatch.logicalConditions.setInLogicalConditions([], newState);
    },
  }),
};

export default logicalConditions;
