const columnTab = {
  state: {
    0: { isOpen: false },
    1: { isOpen: true },
    2: { isOpen: true },
  },
  reducers: {
    setColumnTab(state, payload) {
      return { ...state, [payload]: { isOpen: !state[payload].isOpen } };
    },
  },
};
export default columnTab;
