import FormValueType from 'schema/formValueType';
import RegformFieldElement from 'schema/fieldElement';

const getValueTypeFromTypeName = (typeName: RegformFieldElement): FormValueType => {
  switch (typeName) {
    case RegformFieldElement.AccessCode:
    case RegformFieldElement.City:
    case RegformFieldElement.Company:
    case RegformFieldElement.Country:
    case RegformFieldElement.FirstName:
    case RegformFieldElement.Industry:
    case RegformFieldElement.LastName:
    case RegformFieldElement.Phone:
    case RegformFieldElement.PrimaryEmail:
    case RegformFieldElement.SecondaryEmail:
    case RegformFieldElement.StringQuestion:
    case RegformFieldElement.TextQuestion:
    case RegformFieldElement.Title:
      return FormValueType.STRING;
    case RegformFieldElement.Salutation:
      return FormValueType.TEXTCHOICE;
    case RegformFieldElement.FileUploadQuestion:
      return FormValueType.FILE;
    case RegformFieldElement.CheckboxQuestion:
    case RegformFieldElement.WaiverQuestion:
      return FormValueType.BOOLEAN;
    case RegformFieldElement.CheckboxMcQuestion:
    case RegformFieldElement.DropdownMcQuestion:
    case RegformFieldElement.RadioMcQuestion:
      return FormValueType.COLLECTION;
    default:
      return null;
  }
};

export default getValueTypeFromTypeName;
