const formSubmitError = {
  state: { value: false, reason: '' },
  reducers: {
    setFormSubmitError(state, payload) {
      return payload;
    },
  },
};

export default formSubmitError;
