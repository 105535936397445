const orderEndTime = {
  state: Date.now() + 3600 * 1000,
  reducers: {
    setOrderEndTime(state, payload) {
      return payload;
    },
  },
};

export default orderEndTime;
