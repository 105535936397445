const onlineStatus = {
  state: true,
  reducers: {
    setOnlineStatus(_: boolean, payload: boolean): boolean {
      return payload;
    },
  },
};

export default onlineStatus;
