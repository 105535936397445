import { gql } from '@apollo/client';

const orderSetLocale = gql`
  mutation orderSetLocale(
    $orderAccessKey: String!,
    $locale: LocaleEnum!,
  ) {
    orderSetLocale(
      input: {
        orderAccessKey: $orderAccessKey,
        newLocale: $locale
      }
    ) {
      success
      errors {
        details {
          key
          value
        }
        message
        path
        type
      }
    }
  }
`;

export default orderSetLocale;
