import { gql } from '@apollo/client';

const insertComponentMutation = gql`
  mutation insertComponentMutation(
    $position: Int!
    $typeName: String!
    $regFormElementId: ID!
    $targetObjectId: ID!
    $attributes: [RegformBuilderAttributeInput!]!
  ) {
    regformBuilderCollectionAddObject(
      input: {
        regformId: $regFormElementId
        targetObjectId: $targetObjectId
        attribute: "elements"
        position: $position
        newObjectType: $typeName
        attributes: $attributes
      }
    ) {
      success
      errors {
        type
        message
        path
        details {
          value
        }
      }
      targetObject {
        builderAttributes {
          name
          value {
            collectionValue {
              nodes {
                id
                typeName: __typename
              }
            }
          }
        }
      }
      newObject {
        id
        typeName: __typename
        builderAttributes {
          localizable
          name
          value: value @locale(lang: en) {
            booleanValue
            decimalValue
            idValue
            intValue
            stringValue
            fileValue {
              contentType
              fingerprint
              size
              url
            }
          }
          valueType
        }
      }
    }
  }
`;
export default insertComponentMutation;
