import AttendeeUpdateQueue from 'helpers/attendeeUpdateQueue';
import {
  fetchTicketingPageQuery,
} from './Graphql';

const form = {
  state: [],
  reducers: {
    updateForm(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    async initForm({ orderStatus = '', client, queue }, state) {
      if (orderStatus === 'QUOTA_LOCKED') {
        if (state.includeTicketing) {
          await dispatch.currentInQueue.fetchTicketingPage();
          await dispatch.attendee.fetchAttendeeByOrder({ client, queue });
          const appliedDiscountCodes = await dispatch.appliedDiscountCode
            .fetchAppliedDiscountCodes({ client, queue });
          dispatch.formStage.setStageToForm();
          dispatch.appliedDiscountCode.getOrderQuotation({
            queue, client, discountCode: appliedDiscountCodes[0]?.discountCode,
          });
        } else {
          dispatch.attendee.fetchAttendeeByOrder({ client, queue }).then(() => {
            dispatch.formStage.setStageToForm();
          });
        }
        const { orderEndTime } = state;
        const timer = setInterval(() => {
          if (orderEndTime && orderEndTime < Date.now()) {
            dispatch.formError.setOrderExpired();
            console.error('Order has expired');
            clearInterval(timer);
          }
        }, 1000);
      } else {
        dispatch.currentInQueue.fetchLandingPage();
      }
    },
    fetchLandingPage({ client, queue }, state) {
      const published = state.regFormStatus === 'published';
      if (published) {
        if (state.includeTicketing) {
          dispatch.currentInQueue.fetchTicketingPage();
        }
      }
    },
    fetchTicketingPage({ client, queue }, state) {
      return queue.add(
        () => new Promise((resolve, reject) => {
          dispatch.isTicketLoading.setIsTicketLoading(true);
          const languageList = state.languageList.map(({ code }) => (code));
          const [firstLanguage] = languageList;
          if (!state.orderAccessKey) {
            resolve();
            return;
          }
          client
            .query({
              variables: {
                accessKey: state.orderAccessKey,
                en: languageList.includes('en'),
                zh_hk: languageList.includes('zh_hk'),
                zh_cn: languageList.includes('zh_cn'),
                ja: languageList.includes('ja'),
                ko: languageList.includes('ko'),
                ru: languageList.includes('ru'),
              },
              query: fetchTicketingPageQuery,
              fetchPolicy: 'no-cache',
            })
            .then(({ data }) => {
              const {
                orderByAccessKey: {
                  ticketingElement: {
                    discountCodeEnabled,
                    ticketItems,
                  },
                  ticketMaximumPerOrder,
                },
              } = data;
              dispatch.discountCodeEnabled.setDiscountCodeEnabled(discountCodeEnabled);
              dispatch.ticketMaximumPerOrder.setTicketMaximumPerOrder(parseInt(ticketMaximumPerOrder, 10));
              const ticketList = [];
              ticketItems[firstLanguage].forEach((eachRow, index) => {
                const { ticket: { isDefaultTicket } } = ticketItems[firstLanguage][index];
                if (!isDefaultTicket) {
                  let eachTicket = {};
                  languageList.forEach((eachLanguage) => {
                    eachTicket = {
                      ...eachTicket,
                      [eachLanguage]: ticketItems[eachLanguage][index],
                    };
                  });
                  const selectedQuatity = ticketItems[firstLanguage][index].ticket.allowZeroQuantity ?
                    0 : ticketItems[firstLanguage][index].ticket.minimumQuantity || 0;
                  eachTicket = {
                    ...eachTicket,
                    id: ticketItems[firstLanguage][index].ticket.id,
                    selectedQuatity,
                  };
                  ticketList.push(eachTicket);
                }
              });
              dispatch.currentInQueue.getOrderQuotation();
              dispatch.ticket.setTicket(ticketList);
              dispatch.isTicketLoading.setIsTicketLoading(false);
              resolve();
            })
            .catch((error) => {
              dispatch.ticketError.setTicketError({ value: true, reason: 'get_ticket_fail' });
              // window.Sentry.captureException(error);
              console.error(error);
              reject(error);
            });
        }),
      );
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fetchFormPage({ client, queue }, state) {},
    updateComponentAttrMutation({
      valueType,
      targetObjectId,
      attendeeId,
      value,
      callback,
    }, state) {
      const setFormError = () => {
        dispatch.formError.setFormError({ value: true, reason: 'field_update_error' });
      };
      const updatePayload = {
        fieldElementId: targetObjectId,
        attendeeId,
        callback: (success, res) => {
          if (success) {
            if (callback) {
              callback(success, res);
            }
          } else {
            setFormError([]);
          }
        },
        value,
        valueType,
      };
      AttendeeUpdateQueue.add(updatePayload);
    },
    forceComponentAttrMutation() {
      return AttendeeUpdateQueue.flush();
    },
    async formSubmit() {
      dispatch.attendee.validateAndSubmitForm();
    },
  }),
};

export default form;
