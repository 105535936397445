const isLandingPageLoading = {
  state: true,
  reducers: {
    setIsLandingPageLoading(state, payload) {
      return payload;
    },
  },
};

export default isLandingPageLoading;
