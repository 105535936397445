import { createModel } from '@rematch/core';

const availablePaymentMethods = createModel<string[]>({
  state: ['CREDIT_CARD', 'CUSTOM'],
  reducers: {
    setAvailablePaymentMethods(_: string[], payload: string[]) {
      return payload;
    },
  },
});

export default availablePaymentMethods;
