const includeTicketing = {
  state: false,
  reducers: {
    setIncludeTicketing(state, payload) {
      return payload;
    },
  },
};

export default includeTicketing;
