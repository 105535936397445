import { gql } from '@apollo/client';

import fragmentChoiceSelectedCondition from './fragmentChoiceSelectedCondition';

export default gql`
  fragment ConditionElementNode on RegformCondition {
    id
    typeName: __typename
    ...ChoiceSelectedAtomicConditionElementNode
  }
  ${fragmentChoiceSelectedCondition}
`;
