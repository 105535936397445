import { gql } from '@apollo/client';
import fragmentElementNode from './fragments/fragmentElementNode';

const fetchFormQuery = gql`
  query fetchFormQueryMultiLocale($registrationFormId: ID!, $after: String!, $first: Int) {
    node(id: $registrationFormId) {
      ... on RegformV3Form {
        mainForm {
          pages {
            nodes {
              elements(after: $after, first: $first) {
                pageInfo {
                  endCursor
                  hasNextPage
                }
                edges {
                  en: node @locale(lang: en) {
                    ...ElementNode
                    __typename
                  }
                  zh_hk: node @locale(lang: zh_hk) {
                    ...ElementNode
                    __typename
                  }
                  zh_cn: node @locale(lang: zh_cn) {
                    ...ElementNode
                    __typename
                  }
                  ja: node @locale(lang: ja) {
                    ...ElementNode
                    __typename
                  }
                  ko: node @locale(lang: ko) {
                    ...ElementNode
                    __typename
                  }
                  ru: node @locale(lang: ru) {
                    ...ElementNode
                    __typename
                  }
                  __typename
                }
              }
            }
          }
        }
      }
    }
  }
  ${fragmentElementNode}
`;

export default fetchFormQuery;
