const showNoTicketModal = {
  state: false,
  reducers: {
    setNoTicketModal(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    toggleNoTicketModal(result, state) {
      dispatch.showNoTicketModal.setNoTicketModal(!state.showNoTicketModal);
    },
  }),
};

export default showNoTicketModal;
