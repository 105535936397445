const mainFormTotalCount = {
  state: {
    total: 0,
    fetched: 0,
  },
  reducers: {
    updateMainFormTotalCount(state, attr, value) {
      return {
        ...state,
        [attr]: value,
      };
    },
  },
};

export default mainFormTotalCount;
