const regFormStatus = {
  state: '',
  reducers: {
    setRegFormStatus(state, payload) {
      return payload;
    },
  },
};

export default regFormStatus;
