const selectedRegFormStatus = {
  state: 'published',
  reducers: {
    setSelectedRegFormStatus(state, payload) {
      return payload;
    },
  },
  effects: (dispatch) => ({
    updateSelectedRegFormStatus(payload) {
      dispatch.selectedRegFormStatus.setSelectedRegFormStatus(payload);
      dispatch.currentInQueue.fetchLandingPageQuery();
    },
  }),
};
export default selectedRegFormStatus;
